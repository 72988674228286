import React from 'react'
import {Helmet} from 'react-helmet-async';
import {Link} from 'react-router-dom';
import AdBox from "../../components/AdBox";
import {getBaseLevelInfo, validGameType} from "../../data";
import {capitalize, getTitle} from "../../utils";
import NotFound from "../404";
import './SolutionIndex.scss'

interface IProps {
    match: any
}

export default class SolutionIndex extends React.Component<IProps> {

    render() {
        const gameType = this.props.match.params.gameType

        if (validGameType(gameType)) {
            const levels = getBaseLevelInfo(gameType)
            return (
                <section className="level-selector">
                    <Helmet>
                        <title>{getTitle(`${capitalize(gameType)} Solutions`)}</title>
                    </Helmet>

                    <h1>{`${capitalize(gameType)} Solutions`}</h1>

                    <div className="solution-boxes">
                        {levels.map(level =>
                            <Link to={`/${gameType}/level${level.level}`} key={level.level} className="solution-box">
                                <b>Level {level.level}</b><br/>{level.name}
                            </Link>
                        )}
                    </div>

                    <AdBox/>
                </section>
            )
        }
        // if not handled above, return 404
        return <NotFound/>
    }
}
