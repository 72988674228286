import React from 'react'
import {Helmet} from 'react-helmet-async';
import AdBox from "../../components/AdBox";
import GameSelector from "../../components/GameSelector";
import {getTitle} from "../../utils";
import './Home.scss'

export default class Home extends React.PureComponent {

    render() {
        return (
            <section className="home">
                <Helmet>
                    <title>{getTitle("Home")}</title>
                </Helmet>
                {/*TODO: see if this happens*/}
                {console.log("loading home")}

                <div className="game-boxes">
                    <GameSelector
                        gameType="original"
                        description="Find solutions to the original CrossMe game"
                        floatDirection="left"
                    />
                    <GameSelector
                        gameType="color"
                        description="Find solutions to the CrossMe Color game"
                        floatDirection="right"
                    />
                </div>

                <AdBox/>
            </section>
        )
    }
}
