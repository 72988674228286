import React from 'react'
import {Link} from 'react-router-dom';
import {tagline, website} from "../../strings";
import HeaderNavigation from "../HeaderNavigation";
import './Header.scss'

export default class Header extends React.PureComponent {

    render() {
        return (
            <header className="header">
                <div className="header-data">
                    <div className="header-name">
                        {
                            window.location.pathname === "/" ?
                                <h1 className="title"><Link to="/">{website}</Link></h1> :
                                <h2 className="title"><Link to="/">{website}</Link></h2>
                        }

                        <p className="tagline">{tagline}</p>
                    </div>

                    {/*<div id="search-button" className="search-button">*/}
                    {/*<p className="search">Search TODO</p>*/}
                    {/*</div>*/}

                    {/*<div id="search-div">*/}
                    {/*    <div*/}
                    {/*            className="gcse-search"*/}
                    {/*            data-resultsUrl="http://www.crossmesolutions.com"*/}
                    {/*            data-newWindow="true"*/}
                    {/*            data-queryParameterName="search"*/}
                    {/*    ></div>*/}
                    {/*</div>*/}
                </div>

                <HeaderNavigation/>
            </header>
        )
    }
}
