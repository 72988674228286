import colorData from './color'
import originalData from './original'
import {BaseLevelData} from "./types";

export const validGameType = (gameType: string) => {
    return gameType === "original" || gameType === "color"
}

const getDatabase = (gameType: string) => {
    switch (gameType) {
        case "color":
            return colorData
        case "original":
            return originalData
    }
}

export const getBaseLevelInfo = (gameType: string) => {
    const database = getDatabase(gameType)

    const uniqueLevels: { level: number, name: string }[] = []
    database.forEach((level: BaseLevelData) => {
        uniqueLevels.push({level: level.level, name: level.name})
    })
    return uniqueLevels
}

export const getBaseLevelData = (gameType: string, baseLevelNumber: number) => {
    const database = getDatabase(gameType)
    return database.find(b => b.level == baseLevelNumber)
}

export const getPrevLevel = (gameType: string, baseLevelNumber: number, levelNumber: number) => {
    const returnObj = {
        baseLevelNumber: baseLevelNumber,
        levelData: undefined
    }
    let baseLevelData = getBaseLevelData(gameType, baseLevelNumber)
    if (baseLevelData !== undefined) {
        if (levelNumber === 0) {
            if ((baseLevelNumber - 1) === 0) {
                baseLevelNumber = getBaseLevelInfo(gameType).length
            } else {
                baseLevelNumber--
            }
            baseLevelData = getBaseLevelData(gameType, baseLevelNumber)
            levelNumber = baseLevelData.levels.length
        }
        returnObj.baseLevelNumber = baseLevelNumber
        returnObj.levelData = baseLevelData.levels.find(l => l.level == levelNumber)
    }
    return returnObj
}

export const getNextLevel = (gameType: string, baseLevelNumber: number, levelNumber: number) => {
    const returnObj = {
        baseLevelNumber: baseLevelNumber,
        levelData: undefined
    }
    let baseLevelData = getBaseLevelData(gameType, baseLevelNumber)

    if (baseLevelData !== undefined) {
        const maxBaseLevels = getBaseLevelInfo(gameType).length

        if (levelNumber > baseLevelData.levels.length) {
            if ((baseLevelNumber + 1) > maxBaseLevels) {
                baseLevelNumber = 1
            } else {
                baseLevelNumber++
            }
            baseLevelData = getBaseLevelData(gameType, baseLevelNumber)
            levelNumber = 1
        }
        returnObj.baseLevelNumber = baseLevelNumber
        returnObj.levelData = baseLevelData.levels.find(l => l.level == levelNumber)
    }
    return returnObj
}
