import React from 'react'
import {Link} from 'react-router-dom'

import './Footer.scss'

export default class Footer extends React.PureComponent {

    render() {
        return (
            <footer className="footer">
                <nav className="footer-nav">
                    <p className="footer-item">&copy; CrossMe Solutions</p>
                    <Link to="/original" className="footer-item">Original Solutions</Link>
                    <Link to="/color" className="footer-item">Color Solutions</Link>
                    <Link to="/download" className="footer-item">Download Game</Link>
                </nav>
            </footer>
        )
    }
}
