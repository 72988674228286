import {ConnectedRouter} from 'connected-react-router'
import React, {useEffect} from 'react';
import ReactDOM from 'react-dom';
import ReactGA from "react-ga";
import {Helmet, HelmetProvider} from 'react-helmet-async';
import {Provider} from 'react-redux';
import configureStore, {history} from './store'
import Footer from "./components/Footer";
import Header from './components/Header'
import './index.scss';
import Routes from "./routes";
import {website} from "./strings";

const store = configureStore()

interface MainProps {
}

const Index: React.FunctionComponent<MainProps> = () => {
    ReactGA.initialize('UA-39407089-1');
    history.listen(location => {
        ReactGA.set({page: location.pathname});
        ReactGA.pageview(location.pathname);
    });
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search)
    }, [history])

    return (
        <React.StrictMode>
            <Provider store={store}>
                <HelmetProvider>
                    <ConnectedRouter history={history}>
                        <div className="wrapper">
                            <Helmet>
                                <title>{website}</title>
                                <link
                                    href='//fonts.googleapis.com/css?family=Russo+One'
                                    rel='stylesheet'
                                    type='text/css'
                                />
                                <meta
                                    name="description"
                                    content="Complete solutions for the popular CrossMe and CrossMe Color Android Games"
                                />
                                <meta
                                    name="keywords"
                                    content="android, crossme, crossme color, crossme solutions, crossme color solutions, solutions, help, ios, apple"
                                />
                            </Helmet>
                            <Header/>
                            <main id="content" className="content">
                                <Routes/>
                            </main>
                            <Footer/>
                        </div>
                    </ConnectedRouter>
                </HelmetProvider>
            </Provider>
        </React.StrictMode>
    )
}

ReactDOM.render(
    <Index/>,
    document.getElementById('root')
)

