import React from 'react'

import './DownloadBox.scss'

type LinkData = {
    name: string
    url: string
}

interface IProps {
    platform: string
    originalLinks: LinkData[]
    colorLinks: LinkData[]
}

export default class DownloadBox extends React.PureComponent<IProps> {

    getLinks(links: LinkData[]) {
        let idx = 0
        return links.map(link =>
            <dd key={idx++}>
                <a
                    href={link.url}
                    target="_blank"
                    rel="noreferrer"
                >
                    {link.name}
                </a>
            </dd>
        )
    }

    render() {
        const {platform, originalLinks, colorLinks} = this.props
        return (
            <section className="download-box">
                <h2>{platform}</h2>
                <dl>
                    <dt>CrossMe Original</dt>
                    {this.getLinks(originalLinks)}

                    <dt>CrossMe Color</dt>
                    {this.getLinks(colorLinks)}
                </dl>
            </section>
        )
    }
}
