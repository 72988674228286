import React from 'react'
import {Route, Switch} from 'react-router-dom'
import NotFound from "../pages/404";
import About from "../pages/about";
import Download from "../pages/download";
import Home from "../pages/home";
import Level from "../pages/level";
import LevelIndex from "../pages/level-index";
import SolutionIndex from "../pages/solution-index";

const Routes: React.FunctionComponent = () => (
    <Switch>
        <Route exact path="/" component={Home}/>
        <Route exact path="/about" component={About}/>
        <Route exact path="/download" component={Download}/>
        <Route exact path="/:gameType" component={SolutionIndex}/>
        <Route path={`/:gameType/level:baseLevel/:bLevel-:level-:name`} component={Level}/>
        <Route path={`/:gameType/level:level`} component={LevelIndex}/>
        <Route component={NotFound}/>
    </Switch>
)

export default Routes
