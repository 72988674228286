import React from 'react'
import {Helmet} from 'react-helmet-async';
import AdBox from "../../components/AdBox";
import DownloadBox from "../../components/DownloadBox";
import {getTitle} from "../../utils";
import './Download.scss'

export default class Download extends React.PureComponent {

    render() {
        return (
            <section className="download">
                <Helmet>
                    <title>{getTitle("Download")}</title>
                </Helmet>

                <h1>Download</h1>

                <div className="download-boxes">
                    <DownloadBox
                        platform="Android"
                        originalLinks={[
                            {
                                name: "Google Play",
                                url: "https://play.google.com/store/apps/details?id=com.mobiledynamix.crossme",
                            },
                            {
                                name: "Amazon Appstore",
                                url: "http://www.amazon.com/gp/product/B007JM4CWY/ref=as_li_ss_tl?ie=UTF8&camp=1789&creative=390957&creativeASIN=B007JM4CWY&linkCode=as2&tag=stecrasblo-20",
                            },
                        ]}
                        colorLinks={[
                            {
                                name: "Google Play",
                                url: "https://play.google.com/store/apps/details?id=com.mobiledynamix.crossmecolor",
                            },
                            {
                                name: "Amazon Appstore",
                                url: "http://www.amazon.com/gp/product/B008D28HYS/ref=as_li_ss_tl?ie=UTF8&camp=1789&creative=390957&creativeASIN=B008D28HYS&linkCode=as2&tag=stecrasblo-20",
                            },
                        ]}
                    />

                    <DownloadBox
                        platform="iOS"
                        originalLinks={[
                            {
                                name: "iTunes Store",
                                url: "https://itunes.apple.com/us/app/crossme/id574857255?mt=8",
                            },
                        ]}
                        colorLinks={[
                            {
                                name: "iTunes Store",
                                url: "https://itunes.apple.com/us/app/crossme-color/id595085393?mt=8",
                            },
                        ]}
                    />
                </div>

                <AdBox/>
            </section>
        )
    }
}
