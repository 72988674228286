import React from 'react'
import {Helmet} from 'react-helmet-async';
import {Link} from "react-router-dom";
import AdBox from "../../components/AdBox";
import {getBaseLevelData, getNextLevel, getPrevLevel, validGameType} from "../../data";
import {LevelData} from "../../data/types";
import {formatName, getLevelHeading, getLevelImage, getLevelLink, getNewClass, getTitle} from "../../utils";
import NotFound from "../404";
import './Level.scss'


interface IProps {
    match: any
}

export default class Level extends React.PureComponent<IProps> {

    getOtherSolution(gameType: string, baseLevel: number, level: LevelData, next: boolean) {
        const prefix = next ? "next" : "prev"
        const arrow = next ? ">" : "<"
        if (level !== undefined) {
            return (
                <p className={`${prefix}-solution solution-item ${getNewClass(level)}`}>
                    <Link to={getLevelLink(gameType, baseLevel, level)}>
                        {getLevelHeading(baseLevel, level)}
                        <br/>
                        <span className="arrow">{arrow}</span>
                        <br/>
                        {getLevelImage(gameType, baseLevel, level)}
                    </Link>
                </p>
            )
        } else {
            return (
                <p className={`${prefix}-solution solution-item no-item`}/>
            )
        }
    }

    getSolution(gameType: string, baseLevel: number, level: LevelData) {
        return (
            <p className="this-solution solution-item">
                {getLevelImage(gameType, baseLevel, level)}
            </p>
        )
    }

    render() {
        const gameType = this.props.match.params.gameType
        const name = this.props.match.params.name

        if (this.props.match.isExact) {
            if (validGameType(gameType)) {
                const baseLevelNumber = parseInt(this.props.match.params.baseLevel)
                const levelNumber = parseInt(this.props.match.params.level)
                const baseLevelData = getBaseLevelData(gameType, baseLevelNumber)
                if (baseLevelData !== undefined) {
                    let levelData = baseLevelData.levels.find(l => l.level === levelNumber)
                    if (levelData !== undefined) {
                        if (formatName(levelData.name) === name) {
                            const prevLevelData = getPrevLevel(gameType, baseLevelNumber, levelNumber - 1)
                            const nextLevelData = getNextLevel(gameType, baseLevelNumber, levelNumber + 1)

                            const heading = getLevelHeading(baseLevelNumber, levelData)
                            return (
                                <section className="level">
                                    <Helmet>
                                        <title>{getTitle(`${heading} Solutions`)}</title>
                                    </Helmet>

                                    <h1 className={getNewClass(levelData)}>{heading}</h1>

                                    <div className="solutions">
                                        {this.getSolution(gameType, baseLevelNumber, levelData)}

                                        {this.getOtherSolution(gameType, prevLevelData.baseLevelNumber, prevLevelData.levelData, false)}

                                        {this.getOtherSolution(gameType, nextLevelData.baseLevelNumber, nextLevelData.levelData, true)}
                                    </div>

                                    <AdBox/>
                                </section>
                            )
                        }
                    }
                }
            }
        }
        // if not handled above, return 404
        return <NotFound/>
    }
}
