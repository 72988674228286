import React from 'react'
import {Helmet} from 'react-helmet-async';
import {Link} from "react-router-dom";
import AdBox from "../../components/AdBox";
import {getBaseLevelData, validGameType} from "../../data";
import {LevelData} from "../../data/types";
import {capitalize, getLevelHeading, getLevelImage, getLevelLink, getNewClass, getTitle} from "../../utils";
import NotFound from "../404";
import './LevelIndex.scss'

interface IProps {
    match: any
}

export default class LevelIndex extends React.PureComponent<IProps> {

    loadLevels(gameType: string, baseLevel: number, levels: LevelData[]) {
        let content = []
        let count = 0
        let modValue = 10
        if (levels.length > 20)
            modValue = Math.floor(levels.length / 3)

        levels.forEach((level) => {
            count++
            if ((count % modValue) === 0)
                content.push(this.getAd(count))
            content.push(this.getLevelBox(count, gameType, baseLevel, level))
        })

        return content
    }

    getLevelBox(count: number, gameType: string, baseLevel: number, level: LevelData) {
        return (
            <Link
                to={getLevelLink(gameType, baseLevel, level)}
                className={`${getNewClass(level)} level-box`}
                key={count}
            >
                <b>{getLevelHeading(baseLevel, level)}</b>
                <br/>
                {level.name}
                <br/>
                {getLevelImage(gameType, baseLevel, level)}
            </Link>
        )
    }

    getAd(count: number) {
        return (
            <AdBox key={count} square={true} className="level-box"/>
        )
    }

    render() {
        const gameType = this.props.match.params.gameType

        if (this.props.match.isExact) {
            if (validGameType(gameType)) {
                const level = parseInt(this.props.match.params.level)
                const baseLevelData = getBaseLevelData(gameType, level)
                if (baseLevelData !== undefined) {
                    const levelName = baseLevelData.name
                    const heading = `${capitalize(gameType)} Level ${level} Solutions - ${levelName}`
                    return (
                        <section className="puzzle-selector">
                            <Helmet>
                                <title>{getTitle(heading)}</title>
                            </Helmet>

                            <h1>{heading}</h1>

                            <div className="level-boxes">
                                {this.loadLevels(gameType, baseLevelData.level, baseLevelData.levels)}
                            </div>
                        </section>
                    )
                }
            }
        }
        // if not handled above, return 404
        return <NotFound/>
    }
}
