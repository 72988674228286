import React from 'react'
import {Helmet} from 'react-helmet-async';
import {getTitle} from "../../utils";
import './NotFound.scss'

export default class NotFound extends React.PureComponent {

    render() {
        const title = "404: Page not found"
        return (
            <section className="not-found">
                <Helmet>
                    <title>{getTitle(title)}</title>
                </Helmet>

                <h1>{title}</h1>

                <p>Thanks for visiting CrossMeSolutions.com, the page you are looking for could not be found. Most
                    likely you arrived at this page due to a change in the level numbering.</p>

                <p>Use the menu above to try and locate the puzzle you are looking for.</p>
            </section>
        )
    }
}
