import React from 'react'
import {Helmet} from 'react-helmet-async';
import AdBox from "../../components/AdBox";
import {getTitle} from "../../utils";
import './About.scss'

export default class About extends React.PureComponent {

    render() {
        return (
            <section className="about">
                <Helmet>
                    <title>{getTitle("About")}</title>
                </Helmet>

                <h1>About</h1>

                <p>This website was put together by a fan of the game as a way of helping people advance past a
                    point where they may get stuck.</p>

                <AdBox/>
            </section>
        )
    }
}
