import React from 'react'
import {Link} from 'react-router-dom';
import {capitalize} from "../../utils";
import './HeaderNavigation.scss'


export default class HeaderNavigation extends React.PureComponent {

    getGameLinks(gameType: string) {
        return (
            <Link to={`/${gameType}`} className="nav-item">{capitalize(gameType)} Solutions</Link>
        )
    }

    render() {
        return (
            <nav className="main-nav" id="site-navigation" role="navigation">
                {this.getGameLinks("original")}
                {this.getGameLinks("color")}
                <Link
                    to="/download"
                    className="nav-item"
                >
                    Download Game
                </Link>
                <Link
                    to="/about"
                    className="nav-item"
                >
                    About
                </Link>
            </nav>
        )
    }
}
