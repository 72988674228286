import React from 'react'
import './AdBox.scss'

interface IProps {
    square?: boolean
    className?: string
}

export default class AdBox extends React.PureComponent<IProps> {

    componentDidMount() {
        // @ts-ignore
        (window.adsbygoogle = window.adsbygoogle || []).push({});
    }

    render() {
        let {square, className} = this.props
        if (className === undefined)
            className = ""
        if (square === undefined)
            square = false
        return (
            <div className={`ad ${className}`}>
                <ins
                    className="adsbygoogle"
                    style={{display: "block"}}
                    data-ad-client="ca-pub-3722487639898515"
                    data-ad-slot={square ? "2806168474" : "8992302878"}
                    data-ad-format={square ? "rectangle" : "auto"}
                    data-full-width-responsive="true"
                />
            </div>
        )
    }
}
