import React from "react";
import {LevelData} from "./data/types";
import {website} from "./strings";

export const getTitle = (text: string) => {
    let title = `${website}`
    if (text) {
        title = text + " | " + title
    }
    return title
}

export const capitalize = (text: string) => {
    return text.charAt(0).toUpperCase() + text.slice(1)
}

export const formatName = (name: string) => {
    return name.toLowerCase().replace(' ', '-')
}

export const getNewClass = (level: LevelData) => {
    return level.new ? "new-solution" : ""
}

export const getLevelHeading = (baseLevel: number, level: LevelData) => {
    return `Level ${baseLevel}.${level.level} - ${level.name}`
}

export const getLevelPath = (baseLevel: number, level: LevelData) => {
    return `${baseLevel}-${level.level}-${formatName(level.name)}`
}

export const getLevelLink = (gameType: string, baseLevel: number, level: LevelData) => {
    return `/${gameType}/level${baseLevel}/${formatName(getLevelPath(baseLevel, level))}`
}

// require.context("../static/", true, /^\.\/.*\.png/);
// require.context("../public/images", true, /^\.\/.*\.png/);

export const getLevelImage = (gameType: string, baseLevel: number, level: LevelData) => {
    const description = `Solution for ${capitalize(gameType)} CrossMe ${getLevelHeading(baseLevel, level)}`
    return (
        <img
            src={`/images/${gameType}/level${baseLevel}/${formatName(getLevelPath(baseLevel, level))}.png`}
            alt={description}
            title={description}
        />
    )
}
