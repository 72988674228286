import React from 'react'
import {Link} from 'react-router-dom';
import {capitalize} from "../../utils";
import './GameSelector.scss'

interface IProps {
    gameType: string
    description: string
    floatDirection: string
}

export default class GameSelector extends React.PureComponent<IProps> {

    render() {
        const {gameType, description, floatDirection} = this.props
        return (
            <section className={`game-selector game-selector-button-${floatDirection}`}>
                <Link to={`/${gameType}`}>
                    <h2>{capitalize(gameType)}<br/>Solutions</h2>
                    <p>{description}</p>
                    <p className="button">Get Solutions</p>
                </Link>
            </section>
        )
    }
}
