import {BaseLevelData} from "./types";

const colorData: BaseLevelData[] = [
    {
        level: 1,
        name: 'Sohei',
        levels: [
            {
                level: 1,
                name: 'Tutorial',
                new: false,
            },
            {
                level: 2,
                name: 'Tutorial',
                new: false,
            },
            {
                level: 3,
                name: 'Tutorial',
                new: false,
            },
            {
                level: 4,
                name: 'Tutorial',
                new: false,
            },
            {
                level: 5,
                name: 'Tutorial',
                new: false,
            },
            {
                level: 6,
                name: 'Tutorial',
                new: false,
            },
            {
                level: 7,
                name: 'Tree',
                new: false,
            },
            {
                level: 8,
                name: 'House',
                new: false,
            },
            {
                level: 9,
                name: 'Traffic lights',
                new: false,
            },
            {
                level: 10,
                name: 'Sun',
                new: false,
            },
            {
                level: 11,
                name: 'Fruit',
                new: false,
            },
            {
                level: 12,
                name: 'Duck',
                new: false,
            },
            {
                level: 13,
                name: 'Flower',
                new: false,
            },
            {
                level: 14,
                name: 'Butterfly',
                new: false,
            },
            {
                level: 15,
                name: 'Sign',
                new: false,
            },
            {
                level: 16,
                name: 'Cup',
                new: false,
            },
            {
                level: 17,
                name: 'Ball',
                new: false,
            },
            {
                level: 18,
                name: 'Bee',
                new: false,
            },
            {
                level: 19,
                name: 'Diskette',
                new: false,
            },
            {
                level: 20,
                name: 'Penguin',
                new: false,
            },
            {
                level: 21,
                name: 'Apple',
                new: false,
            },
            {
                level: 22,
                name: 'Swan',
                new: false,
            },
            {
                level: 23,
                name: 'Earth',
                new: false,
            },
            {
                level: 24,
                name: 'Ship',
                new: false,
            },
        ]
    },
    {
        level: 2,
        name: 'Ashigaru',
        levels: [
            {
                level: 1,
                name: 'Mouse',
                new: false,
            },
            {
                level: 2,
                name: 'Old wallet',
                new: false,
            },
            {
                level: 3,
                name: 'Crown',
                new: false,
            },
            {
                level: 4,
                name: 'Dog',
                new: false,
            },
            {
                level: 5,
                name: 'Spaceman',
                new: false,
            },
            {
                level: 6,
                name: 'Berry',
                new: false,
            },
            {
                level: 7,
                name: 'Crab',
                new: false,
            },
            {
                level: 8,
                name: 'Fish',
                new: false,
            },
            {
                level: 9,
                name: 'Hammer',
                new: false,
            },
            {
                level: 10,
                name: 'Pie',
                new: false,
            },
            {
                level: 11,
                name: 'Gnome',
                new: false,
            },
            {
                level: 12,
                name: 'Watermelon',
                new: false,
            },
            {
                level: 13,
                name: 'Sign',
                new: false,
            },
            {
                level: 14,
                name: 'Plane',
                new: false,
            },
            {
                level: 15,
                name: 'Flower',
                new: false,
            },
            {
                level: 16,
                name: 'Frog',
                new: false,
            },
            {
                level: 17,
                name: 'Man',
                new: false,
            },
            {
                level: 18,
                name: 'Tap',
                new: false,
            },
            {
                level: 19,
                name: 'Piggy bank',
                new: false,
            },
            {
                level: 20,
                name: 'Penguin',
                new: false,
            },
            {
                level: 21,
                name: 'Girl',
                new: false,
            },
            {
                level: 22,
                name: 'Chess knight',
                new: false,
            },
            {
                level: 23,
                name: 'Apple',
                new: false,
            },
            {
                level: 24,
                name: 'Emblem',
                new: false,
            },
            {
                level: 25,
                name: 'Cocktail',
                new: false,
            },
            {
                level: 26,
                name: 'Flower',
                new: false,
            },
            {
                level: 27,
                name: 'Opened door',
                new: false,
            },
            {
                level: 28,
                name: 'Snowman',
                new: false,
            },
            {
                level: 29,
                name: 'Deer',
                new: false,
            },
            {
                level: 30,
                name: 'Criminal',
                new: false,
            },
            {
                level: 31,
                name: 'Dolls',
                new: false,
            },
            {
                level: 32,
                name: 'Mushroom',
                new: false,
            },
            {
                level: 33,
                name: 'Burger',
                new: false,
            },
            {
                level: 34,
                name: 'Sun',
                new: false,
            },
            {
                level: 35,
                name: 'Plane',
                new: false,
            },
            {
                level: 36,
                name: 'Fancy cake',
                new: false,
            },
            {
                level: 37,
                name: 'Ice cream',
                new: false,
            },
            {
                level: 38,
                name: 'Mario',
                new: false,
            },
            {
                level: 39,
                name: 'Hammer',
                new: false,
            },
            {
                level: 40,
                name: 'Warrior',
                new: false,
            },
            {
                level: 41,
                name: 'Clown',
                new: false,
            },
            {
                level: 42,
                name: 'Meal',
                new: false,
            },
            {
                level: 43,
                name: 'Game',
                new: false,
            },
            {
                level: 44,
                name: 'Gold',
                new: false,
            },
            {
                level: 45,
                name: 'Novel character',
                new: false,
            },
            {
                level: 46,
                name: 'Flower',
                new: false,
            },
            {
                level: 47,
                name: 'Girl',
                new: false,
            },
            {
                level: 48,
                name: 'Bunny',
                new: false,
            },
            {
                level: 49,
                name: 'Face',
                new: false,
            },
            {
                level: 50,
                name: 'Kite',
                new: false,
            },
            {
                level: 51,
                name: 'Dinosaur',
                new: false,
            },
            {
                level: 52,
                name: 'Clown',
                new: false,
            },
            {
                level: 53,
                name: 'Flower',
                new: false,
            },
            {
                level: 54,
                name: 'Cartoon character',
                new: false,
            },
            {
                level: 55,
                name: 'Cerberus',
                new: false,
            },
            {
                level: 56,
                name: 'Dog',
                new: false,
            },
            {
                level: 57,
                name: 'Birds',
                new: false,
            },
            {
                level: 58,
                name: 'People',
                new: false,
            },
            {
                level: 59,
                name: 'Lion',
                new: false,
            },
            {
                level: 60,
                name: 'Priest',
                new: false,
            },
            {
                level: 61,
                name: 'Tree',
                new: false,
            },
            {
                level: 62,
                name: 'Bird',
                new: false,
            },
            {
                level: 63,
                name: 'Face',
                new: false,
            },
            {
                level: 64,
                name: 'Car',
                new: false,
            },
            {
                level: 65,
                name: 'Hotdog',
                new: false,
            },
            {
                level: 66,
                name: 'Girl',
                new: false,
            },
            {
                level: 67,
                name: 'Candy',
                new: false,
            },
            {
                level: 68,
                name: 'Frog',
                new: false,
            },
            {
                level: 69,
                name: 'Creature',
                new: false,
            },
            {
                level: 70,
                name: 'Apple',
                new: false,
            },
            {
                level: 71,
                name: 'Cat',
                new: false,
            },
        ]
    },
    {
        level: 3,
        name: 'Bushi',
        levels: [
            {
                level: 1,
                name: 'Island',
                new: false,
            },
            {
                level: 2,
                name: 'Couple',
                new: false,
            },
            {
                level: 3,
                name: 'Man',
                new: false,
            },
            {
                level: 4,
                name: 'Police',
                new: false,
            },
            {
                level: 5,
                name: 'Plane',
                new: false,
            },
            {
                level: 6,
                name: 'Animals',
                new: false,
            },
            {
                level: 7,
                name: 'Geisha',
                new: false,
            },
            {
                level: 8,
                name: 'Girl',
                new: false,
            },
            {
                level: 9,
                name: 'Man',
                new: false,
            },
            {
                level: 10,
                name: 'Guitar',
                new: false,
            },
            {
                level: 11,
                name: 'Browser',
                new: false,
            },
            {
                level: 12,
                name: 'Christmas tree',
                new: false,
            },
            {
                level: 13,
                name: 'Ice cream',
                new: false,
            },
            {
                level: 14,
                name: 'Dog',
                new: false,
            },
            {
                level: 15,
                name: 'Tiger',
                new: false,
            },
            {
                level: 16,
                name: 'Killer',
                new: false,
            },
            {
                level: 17,
                name: 'Face',
                new: false,
            },
            {
                level: 18,
                name: 'Cartoon character',
                new: false,
            },
            {
                level: 19,
                name: 'Bird',
                new: false,
            },
            {
                level: 20,
                name: 'Balloons',
                new: false,
            },
            {
                level: 21,
                name: 'Helicopter',
                new: false,
            },
            {
                level: 22,
                name: 'Spaceship',
                new: false,
            },
            {
                level: 23,
                name: 'Creature',
                new: false,
            },
            {
                level: 24,
                name: 'Face',
                new: false,
            },
            {
                level: 25,
                name: 'Star',
                new: false,
            },
            {
                level: 26,
                name: 'Dream',
                new: false,
            },
            {
                level: 27,
                name: 'Face',
                new: false,
            },
            {
                level: 28,
                name: 'Gnome',
                new: false,
            },
            {
                level: 29,
                name: 'Man',
                new: false,
            },
            {
                level: 30,
                name: 'Cat',
                new: false,
            },
            {
                level: 31,
                name: 'Cat',
                new: false,
            },
            {
                level: 32,
                name: 'Vase',
                new: false,
            },
            {
                level: 33,
                name: 'Detective',
                new: false,
            },
            {
                level: 34,
                name: 'Candle',
                new: false,
            },
            {
                level: 35,
                name: 'Castle',
                new: false,
            },
            {
                level: 36,
                name: 'Face',
                new: false,
            },
            {
                level: 37,
                name: 'Snail',
                new: false,
            },
            {
                level: 38,
                name: 'Earth',
                new: false,
            },
            {
                level: 39,
                name: 'Binoculars',
                new: false,
            },
            {
                level: 40,
                name: 'Amphora',
                new: false,
            },
            {
                level: 41,
                name: 'Shore',
                new: false,
            },
            {
                level: 42,
                name: 'Smile',
                new: false,
            },
            {
                level: 43,
                name: 'Apple',
                new: false,
            },
            {
                level: 44,
                name: 'Ladybird',
                new: false,
            },
            {
                level: 45,
                name: 'Monk',
                new: false,
            },
            {
                level: 46,
                name: 'Flower',
                new: false,
            },
            {
                level: 47,
                name: 'Heart',
                new: false,
            },
            {
                level: 48,
                name: 'Lizard',
                new: false,
            },
            {
                level: 49,
                name: 'Hedgehog',
                new: false,
            },
            {
                level: 50,
                name: 'Apple',
                new: false,
            },
            {
                level: 51,
                name: 'Snowman',
                new: false,
            },
            {
                level: 52,
                name: 'Man',
                new: false,
            },
            {
                level: 53,
                name: 'Boy',
                new: false,
            },
            {
                level: 54,
                name: 'Banana',
                new: false,
            },
            {
                level: 55,
                name: 'Ornament',
                new: false,
            },
            {
                level: 56,
                name: 'Flower',
                new: false,
            },
            {
                level: 57,
                name: 'Mushrooms',
                new: false,
            },
            {
                level: 58,
                name: 'Flower',
                new: false,
            },
            {
                level: 59,
                name: 'Girl',
                new: false,
            },
            {
                level: 60,
                name: 'Creature',
                new: false,
            },
            {
                level: 61,
                name: 'Fairy tale character',
                new: false,
            },
            {
                level: 62,
                name: 'Meal',
                new: false,
            },
            {
                level: 63,
                name: 'House',
                new: false,
            },
            {
                level: 64,
                name: 'Fire',
                new: false,
            },
            {
                level: 65,
                name: 'Halloween',
                new: false,
            },
            {
                level: 66,
                name: 'Meal',
                new: false,
            },
            {
                level: 67,
                name: 'Balloon',
                new: false,
            },
            {
                level: 68,
                name: 'Pisces',
                new: false,
            },
            {
                level: 69,
                name: 'Emblem',
                new: false,
            },
            {
                level: 70,
                name: 'Meal',
                new: false,
            },
            {
                level: 71,
                name: 'Fancy cake',
                new: false,
            },
        ]
    },
    {
        level: 4,
        name: 'Shindbi',
        levels: [
            {
                level: 1,
                name: 'Plane',
                new: false,
            },
            {
                level: 2,
                name: 'Puppy',
                new: false,
            },
            {
                level: 3,
                name: 'Ladybird',
                new: false,
            },
            {
                level: 4,
                name: 'Christmas tree',
                new: false,
            },
            {
                level: 5,
                name: 'Cancer',
                new: false,
            },
            {
                level: 6,
                name: 'Cake',
                new: false,
            },
            {
                level: 7,
                name: 'Ape',
                new: false,
            },
            {
                level: 8,
                name: 'Fox',
                new: false,
            },
            {
                level: 9,
                name: 'Watermelon',
                new: false,
            },
            {
                level: 10,
                name: 'Cartoon character',
                new: false,
            },
            {
                level: 11,
                name: 'Fur Seal',
                new: false,
            },
            {
                level: 12,
                name: 'Shadow',
                new: false,
            },
            {
                level: 13,
                name: 'Skates',
                new: false,
            },
            {
                level: 14,
                name: 'Flask',
                new: false,
            },
            {
                level: 15,
                name: 'Apple Tree',
                new: false,
            },
            {
                level: 16,
                name: 'House',
                new: false,
            },
            {
                level: 17,
                name: 'Snake',
                new: false,
            },
            {
                level: 18,
                name: 'Japanese',
                new: false,
            },
            {
                level: 19,
                name: 'Fish',
                new: false,
            },
            {
                level: 20,
                name: 'Lamp',
                new: false,
            },
            {
                level: 21,
                name: 'Lock',
                new: false,
            },
            {
                level: 22,
                name: 'Flower',
                new: false,
            },
            {
                level: 23,
                name: 'Man',
                new: false,
            },
            {
                level: 24,
                name: 'Ship',
                new: false,
            },
            {
                level: 25,
                name: 'Cat',
                new: false,
            },
            {
                level: 26,
                name: 'Carousel',
                new: false,
            },
            {
                level: 27,
                name: 'Elephant',
                new: false,
            },
            {
                level: 28,
                name: 'Flag',
                new: false,
            },
            {
                level: 29,
                name: 'Butterfly',
                new: false,
            },
            {
                level: 30,
                name: 'Chicken',
                new: false,
            },
            {
                level: 31,
                name: 'Rose',
                new: false,
            },
            {
                level: 32,
                name: 'Peacock',
                new: false,
            },
            {
                level: 33,
                name: 'Cartoon character',
                new: false,
            },
            {
                level: 34,
                name: 'Game character',
                new: false,
            },
            {
                level: 35,
                name: 'Clown',
                new: false,
            },
            {
                level: 36,
                name: 'Mushroom',
                new: false,
            },
            {
                level: 37,
                name: 'Panda',
                new: false,
            },
            {
                level: 38,
                name: 'Sakura',
                new: false,
            },
            {
                level: 39,
                name: 'Ship',
                new: false,
            },
            {
                level: 40,
                name: 'Apple',
                new: false,
            },
            {
                level: 41,
                name: 'Spaceship',
                new: false,
            },
            {
                level: 42,
                name: 'Friends',
                new: false,
            },
            {
                level: 43,
                name: 'Dog',
                new: false,
            },
            {
                level: 44,
                name: 'Car',
                new: false,
            },
            {
                level: 45,
                name: 'Globe',
                new: false,
            },
            {
                level: 46,
                name: 'Bear',
                new: false,
            },
            {
                level: 47,
                name: 'Sun',
                new: false,
            },
            {
                level: 48,
                name: 'Sunrise',
                new: false,
            },
            {
                level: 49,
                name: 'Cannon',
                new: false,
            },
            {
                level: 50,
                name: 'Butterfly',
                new: false,
            },
            {
                level: 51,
                name: 'Temple',
                new: false,
            },
            {
                level: 52,
                name: 'Mushroom',
                new: false,
            },
            {
                level: 53,
                name: 'Creature',
                new: false,
            },
            {
                level: 54,
                name: 'Flag',
                new: false,
            },
            {
                level: 55,
                name: 'Elephant',
                new: false,
            },
            {
                level: 56,
                name: 'Wolf',
                new: false,
            },
            {
                level: 57,
                name: 'Taurus',
                new: false,
            },
            {
                level: 58,
                name: 'Boy',
                new: false,
            },
            {
                level: 59,
                name: 'Aim',
                new: false,
            },
            {
                level: 60,
                name: 'Girl',
                new: false,
            },
            {
                level: 61,
                name: 'Ladies',
                new: false,
            },
            {
                level: 62,
                name: 'Crystal ball',
                new: false,
            },
            {
                level: 63,
                name: 'Woman',
                new: false,
            },
            {
                level: 64,
                name: 'Cherry',
                new: false,
            },
            {
                level: 65,
                name: 'Stationery',
                new: false,
            },
            {
                level: 66,
                name: 'Goldfish',
                new: false,
            },
            {
                level: 67,
                name: 'Man',
                new: false,
            },
            {
                level: 68,
                name: 'House',
                new: false,
            },
            {
                level: 69,
                name: 'Man',
                new: false,
            },
            {
                level: 70,
                name: 'Singer',
                new: false,
            },
            {
                level: 71,
                name: 'Kangaroo',
                new: false,
            },
        ]
    },
    {
        level: 5,
        name: 'Ronin',
        levels: [
            {
                level: 1,
                name: 'Balloons',
                new: false,
            },
            {
                level: 2,
                name: 'Rose',
                new: false,
            },
            {
                level: 3,
                name: 'Couple',
                new: false,
            },
            {
                level: 4,
                name: 'Apple',
                new: false,
            },
            {
                level: 5,
                name: 'Crocodile',
                new: false,
            },
            {
                level: 6,
                name: 'Temple',
                new: false,
            },
            {
                level: 7,
                name: 'Sportsman',
                new: false,
            },
            {
                level: 8,
                name: 'Cartoon character',
                new: false,
            },
            {
                level: 9,
                name: 'Mushrooms',
                new: false,
            },
            {
                level: 10,
                name: 'Bunny',
                new: false,
            },
            {
                level: 11,
                name: 'Umbrella',
                new: false,
            },
            {
                level: 12,
                name: 'Dog',
                new: false,
            },
            {
                level: 13,
                name: 'Fox',
                new: false,
            },
            {
                level: 14,
                name: 'Flowers',
                new: false,
            },
            {
                level: 15,
                name: 'Match',
                new: false,
            },
            {
                level: 16,
                name: 'Rainbow',
                new: false,
            },
            {
                level: 17,
                name: 'Cat',
                new: false,
            },
            {
                level: 18,
                name: 'Snake',
                new: false,
            },
            {
                level: 19,
                name: 'Puppy',
                new: false,
            },
            {
                level: 20,
                name: 'Butterfly',
                new: false,
            },
            {
                level: 21,
                name: 'Elephant',
                new: false,
            },
            {
                level: 22,
                name: 'Panda',
                new: false,
            },
            {
                level: 23,
                name: 'Aquarius',
                new: false,
            },
            {
                level: 24,
                name: 'Sportsman',
                new: false,
            },
            {
                level: 25,
                name: 'Cartoon character',
                new: false,
            },
            {
                level: 26,
                name: 'Man',
                new: false,
            },
            {
                level: 27,
                name: 'Couple',
                new: false,
            },
            {
                level: 28,
                name: 'Fish',
                new: false,
            },
            {
                level: 29,
                name: 'Woman',
                new: false,
            },
            {
                level: 30,
                name: 'Rooster',
                new: false,
            },
            {
                level: 31,
                name: 'Animal',
                new: false,
            },
            {
                level: 32,
                name: 'Browser',
                new: false,
            },
            {
                level: 33,
                name: 'Man',
                new: false,
            },
            {
                level: 34,
                name: 'Monument',
                new: false,
            },
            {
                level: 35,
                name: 'Mountaineer',
                new: false,
            },
            {
                level: 36,
                name: 'Fox',
                new: false,
            },
            {
                level: 37,
                name: 'Fairy tale character',
                new: false,
            },
            {
                level: 38,
                name: 'Mexico',
                new: false,
            },
            {
                level: 39,
                name: 'Satellite dish',
                new: false,
            },
            {
                level: 40,
                name: 'Berries',
                new: false,
            },
            {
                level: 41,
                name: 'Man',
                new: false,
            },
            {
                level: 42,
                name: 'Fire Extinguisher',
                new: false,
            },
            {
                level: 43,
                name: 'Traffic lights',
                new: false,
            },
            {
                level: 44,
                name: 'Train',
                new: false,
            },
            {
                level: 45,
                name: 'Donkey',
                new: false,
            },
            {
                level: 46,
                name: 'Berries',
                new: false,
            },
            {
                level: 47,
                name: 'Violin',
                new: false,
            },
            {
                level: 48,
                name: 'Bunny',
                new: false,
            },
            {
                level: 49,
                name: 'Goose',
                new: false,
            },
            {
                level: 50,
                name: 'Cat',
                new: false,
            },
            {
                level: 51,
                name: 'Cow',
                new: false,
            },
            {
                level: 52,
                name: 'Scorpion',
                new: false,
            },
            {
                level: 53,
                name: 'Temple',
                new: false,
            },
            {
                level: 54,
                name: 'Wine',
                new: false,
            },
            {
                level: 55,
                name: 'Bird',
                new: false,
            },
            {
                level: 56,
                name: 'Game',
                new: false,
            },
            {
                level: 57,
                name: 'Face',
                new: false,
            },
            {
                level: 58,
                name: 'Drinking',
                new: false,
            },
            {
                level: 59,
                name: 'Donuts',
                new: false,
            },
            {
                level: 60,
                name: 'Bee',
                new: false,
            },
            {
                level: 61,
                name: 'Little girl',
                new: false,
            },
            {
                level: 62,
                name: 'Cat',
                new: false,
            },
            {
                level: 63,
                name: 'Satellite',
                new: false,
            },
            {
                level: 64,
                name: 'Cartoon',
                new: false,
            },
            {
                level: 65,
                name: 'Frog',
                new: false,
            },
            {
                level: 66,
                name: 'Girl',
                new: false,
            },
            {
                level: 67,
                name: 'Rooster',
                new: false,
            },
            {
                level: 68,
                name: 'Camel',
                new: false,
            },
            {
                level: 69,
                name: 'Hunter',
                new: false,
            },
            {
                level: 70,
                name: 'Geishas',
                new: false,
            },
            {
                level: 71,
                name: 'City',
                new: false,
            },
        ]
    },
    {
        level: 6,
        name: 'Samurai',
        levels: [
            {
                level: 1,
                name: 'Teapot',
                new: false,
            },
            {
                level: 2,
                name: 'Parrot',
                new: false,
            },
            {
                level: 3,
                name: 'Crocodile',
                new: false,
            },
            {
                level: 4,
                name: 'Flower',
                new: false,
            },
            {
                level: 5,
                name: 'Cherry',
                new: false,
            },
            {
                level: 6,
                name: 'Monster',
                new: false,
            },
            {
                level: 7,
                name: 'Whale',
                new: false,
            },
            {
                level: 8,
                name: 'Wolf',
                new: false,
            },
            {
                level: 9,
                name: 'Elf she',
                new: false,
            },
            {
                level: 10,
                name: 'Flower',
                new: false,
            },
            {
                level: 11,
                name: 'Puppy',
                new: false,
            },
            {
                level: 12,
                name: 'Bear',
                new: false,
            },
            {
                level: 13,
                name: 'Monster',
                new: false,
            },
            {
                level: 14,
                name: 'Snowman',
                new: false,
            },
            {
                level: 15,
                name: 'Cartoon character',
                new: false,
            },
            {
                level: 16,
                name: 'Artist',
                new: false,
            },
            {
                level: 17,
                name: 'Snake',
                new: false,
            },
            {
                level: 18,
                name: 'Superhero',
                new: false,
            },
            {
                level: 19,
                name: 'Mushrooms',
                new: false,
            },
            {
                level: 20,
                name: 'Ship',
                new: false,
            },
            {
                level: 21,
                name: 'Flower',
                new: false,
            },
            {
                level: 22,
                name: 'Beach',
                new: false,
            },
            {
                level: 23,
                name: 'Celebration',
                new: false,
            },
            {
                level: 24,
                name: 'Ironing',
                new: false,
            },
            {
                level: 25,
                name: 'Gypsy she',
                new: false,
            },
            {
                level: 26,
                name: 'Musician',
                new: false,
            },
            {
                level: 27,
                name: 'Heart',
                new: false,
            },
            {
                level: 28,
                name: 'Drummer',
                new: false,
            },
            {
                level: 29,
                name: 'Train',
                new: false,
            },
            {
                level: 30,
                name: 'Parrot',
                new: false,
            },
            {
                level: 31,
                name: 'Halloween',
                new: false,
            },
            {
                level: 32,
                name: 'Khokhloma painting',
                new: false,
            },
            {
                level: 33,
                name: 'Singer she',
                new: false,
            },
            {
                level: 34,
                name: 'Fly',
                new: false,
            },
            {
                level: 35,
                name: 'Dragon',
                new: false,
            },
            {
                level: 36,
                name: 'Dynamite',
                new: false,
            },
            {
                level: 37,
                name: 'Cartoon character',
                new: false,
            },
            {
                level: 38,
                name: 'Flowers',
                new: false,
            },
            {
                level: 39,
                name: 'Elephant',
                new: false,
            },
            {
                level: 40,
                name: 'Cartoon character',
                new: false,
            },
            {
                level: 41,
                name: 'Mushrooms',
                new: false,
            },
            {
                level: 42,
                name: 'Gift',
                new: false,
            },
            {
                level: 43,
                name: 'Monument',
                new: false,
            },
            {
                level: 44,
                name: 'Sign',
                new: false,
            },
            {
                level: 45,
                name: 'Temple',
                new: false,
            },
            {
                level: 46,
                name: 'Butterfly',
                new: false,
            },
            {
                level: 47,
                name: 'Dog',
                new: false,
            },
            {
                level: 48,
                name: 'Bell',
                new: false,
            },
            {
                level: 49,
                name: 'Cartoon character',
                new: false,
            },
            {
                level: 50,
                name: 'Cat',
                new: false,
            },
            {
                level: 51,
                name: 'Car',
                new: false,
            },
            {
                level: 52,
                name: 'Nestling',
                new: false,
            },
            {
                level: 53,
                name: 'Libra',
                new: false,
            },
            {
                level: 54,
                name: 'Warrior',
                new: false,
            },
            {
                level: 55,
                name: 'Dance',
                new: false,
            },
            {
                level: 56,
                name: 'Zeus',
                new: false,
            },
            {
                level: 57,
                name: 'Sunflowers',
                new: false,
            },
            {
                level: 58,
                name: 'Cartoon character',
                new: false,
            },
            {
                level: 59,
                name: 'Sword',
                new: false,
            },
            {
                level: 60,
                name: 'Doll',
                new: false,
            },
            {
                level: 61,
                name: 'Cocktail',
                new: false,
            },
            {
                level: 62,
                name: 'Cake',
                new: false,
            },
            {
                level: 63,
                name: 'Man',
                new: false,
            },
            {
                level: 64,
                name: 'Cartoon',
                new: false,
            },
            {
                level: 65,
                name: 'Berries',
                new: false,
            },
            {
                level: 66,
                name: 'Forester',
                new: false,
            },
            {
                level: 67,
                name: 'Coin',
                new: false,
            },
            {
                level: 68,
                name: 'Nurse',
                new: false,
            },
            {
                level: 69,
                name: 'Tiger',
                new: false,
            },
            {
                level: 70,
                name: 'Pianist',
                new: false,
            },
            {
                level: 71,
                name: 'Cartoon',
                new: false,
            },
        ]
    },
    {
        level: 7,
        name: 'Sensei',
        levels: [
            {
                level: 1,
                name: 'Bird',
                new: false,
            },
            {
                level: 2,
                name: 'Christmas',
                new: false,
            },
            {
                level: 3,
                name: 'Meal',
                new: false,
            },
            {
                level: 4,
                name: 'Koala',
                new: false,
            },
            {
                level: 5,
                name: 'Little girl',
                new: false,
            },
            {
                level: 6,
                name: 'Lips',
                new: false,
            },
            {
                level: 7,
                name: 'Cartoon character',
                new: false,
            },
            {
                level: 8,
                name: 'Love',
                new: false,
            },
            {
                level: 9,
                name: 'Burger',
                new: false,
            },
            {
                level: 10,
                name: 'Gift',
                new: false,
            },
            {
                level: 11,
                name: 'Puppy',
                new: false,
            },
            {
                level: 12,
                name: 'Cartoon character',
                new: false,
            },
            {
                level: 13,
                name: 'Cartoon character',
                new: false,
            },
            {
                level: 14,
                name: 'Horse',
                new: false,
            },
            {
                level: 15,
                name: 'Browser',
                new: false,
            },
            {
                level: 16,
                name: 'Truck',
                new: false,
            },
            {
                level: 17,
                name: 'Horse',
                new: false,
            },
            {
                level: 18,
                name: 'Turtle',
                new: false,
            },
            {
                level: 19,
                name: 'Woman',
                new: false,
            },
            {
                level: 20,
                name: 'Matreshka',
                new: false,
            },
            {
                level: 21,
                name: 'Lion',
                new: false,
            },
            {
                level: 22,
                name: 'Turtle',
                new: false,
            },
            {
                level: 23,
                name: 'Pumpkin',
                new: false,
            },
            {
                level: 24,
                name: 'Newlyweds',
                new: false,
            },
            {
                level: 25,
                name: 'Animals',
                new: false,
            },
            {
                level: 26,
                name: 'Leaves',
                new: false,
            },
            {
                level: 27,
                name: 'Motorcyclist',
                new: false,
            },
            {
                level: 28,
                name: 'Bicyclist',
                new: false,
            },
            {
                level: 29,
                name: 'Fairy tale character',
                new: false,
            },
            {
                level: 30,
                name: 'Cartoon character',
                new: false,
            },
            {
                level: 31,
                name: 'Little girl',
                new: false,
            },
            {
                level: 32,
                name: 'Olives',
                new: false,
            },
            {
                level: 33,
                name: 'Vase',
                new: false,
            },
            {
                level: 34,
                name: 'Candle',
                new: false,
            },
            {
                level: 35,
                name: 'Ship',
                new: false,
            },
            {
                level: 36,
                name: 'Watermelons',
                new: false,
            },
            {
                level: 37,
                name: 'Hearts',
                new: false,
            },
            {
                level: 38,
                name: 'Beach',
                new: false,
            },
            {
                level: 39,
                name: 'Turtle',
                new: false,
            },
            {
                level: 40,
                name: 'Ball',
                new: false,
            },
            {
                level: 41,
                name: 'Emergency',
                new: false,
            },
            {
                level: 42,
                name: 'Cafe',
                new: false,
            },
            {
                level: 43,
                name: 'Snowman',
                new: false,
            },
            {
                level: 44,
                name: 'Strawberry',
                new: false,
            },
            {
                level: 45,
                name: 'Trumpet',
                new: false,
            },
            {
                level: 46,
                name: 'Candle',
                new: false,
            },
            {
                level: 47,
                name: 'Doll',
                new: false,
            },
            {
                level: 48,
                name: 'Magic lamp',
                new: false,
            },
            {
                level: 49,
                name: 'Flower',
                new: false,
            },
            {
                level: 50,
                name: 'Crow',
                new: false,
            },
            {
                level: 51,
                name: 'Mother',
                new: false,
            },
            {
                level: 52,
                name: 'Lipstick',
                new: false,
            },
            {
                level: 53,
                name: 'Toucan',
                new: false,
            },
            {
                level: 54,
                name: 'Arch',
                new: false,
            },
            {
                level: 55,
                name: 'Fish',
                new: false,
            },
            {
                level: 56,
                name: 'Butterfly',
                new: false,
            },
            {
                level: 57,
                name: 'Panda',
                new: false,
            },
            {
                level: 58,
                name: 'Boat',
                new: false,
            },
            {
                level: 59,
                name: 'Rose',
                new: false,
            },
            {
                level: 60,
                name: 'Banana',
                new: false,
            },
            {
                level: 61,
                name: 'Ice cream',
                new: false,
            },
            {
                level: 62,
                name: 'Sandals',
                new: false,
            },
            {
                level: 63,
                name: 'Fish',
                new: false,
            },
            {
                level: 64,
                name: 'Cat',
                new: false,
            },
            {
                level: 65,
                name: 'Sport',
                new: false,
            },
            {
                level: 66,
                name: 'Fish',
                new: false,
            },
            {
                level: 67,
                name: 'Clock',
                new: false,
            },
            {
                level: 68,
                name: 'Racer',
                new: false,
            },
            {
                level: 69,
                name: 'Conductor',
                new: false,
            },
            {
                level: 70,
                name: 'Man',
                new: false,
            },
            {
                level: 71,
                name: 'Gypsy she',
                new: false,
            },
        ]
    },
    {
        level: 8,
        name: 'Shogun',
        levels: [
            {
                level: 1,
                name: 'Monster',
                new: false,
            },
            {
                level: 2,
                name: 'Bear',
                new: false,
            },
            {
                level: 3,
                name: 'House',
                new: false,
            },
            {
                level: 4,
                name: 'Farmer',
                new: false,
            },
            {
                level: 5,
                name: 'Flower',
                new: false,
            },
            {
                level: 6,
                name: 'Phone',
                new: false,
            },
            {
                level: 7,
                name: 'City',
                new: false,
            },
            {
                level: 8,
                name: 'Sagittarius',
                new: false,
            },
            {
                level: 9,
                name: 'Pirate',
                new: false,
            },
            {
                level: 10,
                name: 'Flowers',
                new: false,
            },
            {
                level: 11,
                name: 'Frog',
                new: false,
            },
            {
                level: 12,
                name: 'Spruce',
                new: false,
            },
            {
                level: 13,
                name: 'Water-lily',
                new: false,
            },
            {
                level: 14,
                name: 'Tiger',
                new: false,
            },
            {
                level: 15,
                name: 'Couple',
                new: false,
            },
            {
                level: 16,
                name: 'Flower',
                new: false,
            },
            {
                level: 17,
                name: 'Bird',
                new: false,
            },
            {
                level: 18,
                name: 'Cat',
                new: false,
            },
            {
                level: 19,
                name: 'Monument',
                new: false,
            },
            {
                level: 20,
                name: 'Cartoon character',
                new: false,
            },
            {
                level: 21,
                name: 'Snowman',
                new: false,
            },
            {
                level: 22,
                name: 'Vacation',
                new: false,
            },
            {
                level: 23,
                name: 'Japonese many',
                new: false,
            },
            {
                level: 24,
                name: 'Parrots',
                new: false,
            },
            {
                level: 25,
                name: 'Anchor',
                new: false,
            },
            {
                level: 26,
                name: 'Ship',
                new: false,
            },
            {
                level: 27,
                name: 'Giraffe',
                new: false,
            },
            {
                level: 28,
                name: 'Boat',
                new: false,
            },
            {
                level: 29,
                name: 'Love',
                new: false,
            },
            {
                level: 30,
                name: 'Cartoon character',
                new: false,
            },
            {
                level: 31,
                name: 'Fairy tale character',
                new: false,
            },
            {
                level: 32,
                name: 'Robot',
                new: false,
            },
            {
                level: 33,
                name: 'Bird',
                new: false,
            },
            {
                level: 34,
                name: 'Swing',
                new: false,
            },
            {
                level: 35,
                name: 'Bear',
                new: false,
            },
            {
                level: 36,
                name: 'Doctor',
                new: false,
            },
            {
                level: 37,
                name: 'Crows',
                new: false,
            },
            {
                level: 38,
                name: 'Carrot',
                new: false,
            },
            {
                level: 39,
                name: 'Kangaroo',
                new: false,
            },
            {
                level: 40,
                name: 'Cartoon character',
                new: false,
            },
            {
                level: 41,
                name: 'Actor',
                new: false,
            },
            {
                level: 42,
                name: 'Movie character',
                new: false,
            },
            {
                level: 43,
                name: 'Fox',
                new: false,
            },
            {
                level: 44,
                name: 'Grapes',
                new: false,
            },
            {
                level: 45,
                name: 'Sewing-machine',
                new: false,
            },
            {
                level: 46,
                name: 'Beach',
                new: false,
            },
            {
                level: 47,
                name: 'Santa Claus',
                new: false,
            },
            {
                level: 48,
                name: 'Fish',
                new: false,
            },
            {
                level: 49,
                name: 'Skier',
                new: false,
            },
            {
                level: 50,
                name: 'House',
                new: false,
            },
            {
                level: 51,
                name: 'Lily',
                new: false,
            },
            {
                level: 52,
                name: 'Caravan',
                new: false,
            },
            {
                level: 53,
                name: 'Actress',
                new: false,
            },
            {
                level: 54,
                name: 'Surprise',
                new: false,
            },
            {
                level: 55,
                name: 'Actress',
                new: false,
            },
            {
                level: 56,
                name: 'Girl',
                new: false,
            },
            {
                level: 57,
                name: 'Penguin',
                new: false,
            },
            {
                level: 58,
                name: 'Chameleon',
                new: false,
            },
            {
                level: 59,
                name: 'Landscape',
                new: false,
            },
            {
                level: 60,
                name: 'Sword',
                new: false,
            },
            {
                level: 61,
                name: 'Butterfly',
                new: false,
            },
            {
                level: 62,
                name: 'Killer',
                new: false,
            },
            {
                level: 63,
                name: 'Movie',
                new: false,
            },
            {
                level: 64,
                name: 'Bird',
                new: false,
            },
            {
                level: 65,
                name: 'Little girl',
                new: false,
            },
            {
                level: 66,
                name: 'Monster',
                new: false,
            },
        ]
    },
]

export default colorData;
