import {BaseLevelData} from "./types";

const originalData: BaseLevelData[] = [
    {
        level: 1,
        name: 'Sohei',
        levels: [
            {
                level: 1,
                name: 'Tutorial',
                new: false,
            },
            {
                level: 2,
                name: 'Tutorial',
                new: false,
            },
            {
                level: 3,
                name: 'Tutorial',
                new: false,
            },
            {
                level: 4,
                name: 'Tutorial',
                new: false,
            },
            {
                level: 5,
                name: 'Tutorial',
                new: false,
            },
            {
                level: 6,
                name: 'House',
                new: false,
            },
            {
                level: 7,
                name: 'Heart',
                new: false,
            },
            {
                level: 8,
                name: 'Smile',
                new: false,
            },
            {
                level: 9,
                name: 'Umbrella',
                new: false,
            },
            {
                level: 10,
                name: 'House',
                new: false,
            },
            {
                level: 11,
                name: 'Teapot',
                new: false,
            },
            {
                level: 12,
                name: 'Car',
                new: false,
            },
            {
                level: 13,
                name: 'Camera',
                new: false,
            },
            {
                level: 14,
                name: 'Spades',
                new: false,
            },
            {
                level: 15,
                name: 'Dog',
                new: false,
            },
            {
                level: 16,
                name: 'Train',
                new: false,
            },
            {
                level: 17,
                name: 'Bunny',
                new: false,
            },
            {
                level: 18,
                name: 'Ship',
                new: false,
            },
            {
                level: 19,
                name: 'Fish',
                new: false,
            },
            {
                level: 20,
                name: 'Video game',
                new: false,
            },
            {
                level: 21,
                name: 'Helicopter',
                new: false,
            },
            {
                level: 22,
                name: 'Chicken',
                new: false,
            },
            {
                level: 23,
                name: 'Ship',
                new: false,
            },
            {
                level: 24,
                name: 'Swan',
                new: false,
            },
        ]
    },
    {
        level: 2,
        name: 'Ashigaru',
        levels: [
            {
                level: 1,
                name: 'Apple',
                new: false,
            },
            {
                level: 2,
                name: 'Penguin',
                new: false,
            },
            {
                level: 3,
                name: 'Seal',
                new: false,
            },
            {
                level: 4,
                name: 'Prize cup',
                new: false,
            },
            {
                level: 5,
                name: 'Worm',
                new: false,
            },
            {
                level: 6,
                name: 'Cup',
                new: false,
            },
            {
                level: 7,
                name: 'Flower',
                new: false,
            },
            {
                level: 8,
                name: 'Vase',
                new: false,
            },
            {
                level: 9,
                name: 'Rocket',
                new: false,
            },
            {
                level: 10,
                name: 'Snail',
                new: false,
            },
            {
                level: 11,
                name: 'Elephant',
                new: false,
            },
            {
                level: 12,
                name: 'House',
                new: false,
            },
            {
                level: 13,
                name: 'Piggy bank',
                new: false,
            },
            {
                level: 14,
                name: 'Glass',
                new: false,
            },
            {
                level: 15,
                name: 'Hourglass',
                new: false,
            },
            {
                level: 16,
                name: 'Fan',
                new: false,
            },
            {
                level: 17,
                name: 'Palm tree',
                new: false,
            },
            {
                level: 18,
                name: 'Alarm clock',
                new: false,
            },
            {
                level: 19,
                name: 'Cat',
                new: false,
            },
            {
                level: 20,
                name: 'Night',
                new: false,
            },
            {
                level: 21,
                name: 'Ship',
                new: false,
            },
            {
                level: 22,
                name: 'Kettlebell',
                new: false,
            },
            {
                level: 23,
                name: 'Computer',
                new: false,
            },
            {
                level: 24,
                name: 'Sportsman',
                new: false,
            },
            {
                level: 25,
                name: 'Heart',
                new: false,
            },
            {
                level: 26,
                name: 'Diskette',
                new: false,
            },
            {
                level: 27,
                name: 'Face',
                new: false,
            },
            {
                level: 28,
                name: 'Crab',
                new: false,
            },
            {
                level: 29,
                name: 'Radio',
                new: false,
            },
            {
                level: 30,
                name: 'Flower',
                new: false,
            },
            {
                level: 31,
                name: 'Car',
                new: false,
            },
            {
                level: 32,
                name: 'Man',
                new: false,
            },
            {
                level: 33,
                name: 'Ghost',
                new: false,
            },
            {
                level: 34,
                name: 'Cat',
                new: false,
            },
            {
                level: 35,
                name: 'Tap',
                new: false,
            },
            {
                level: 36,
                name: 'Snail',
                new: false,
            },
            {
                level: 37,
                name: 'Ship',
                new: false,
            },
            {
                level: 38,
                name: 'Yin-Yang',
                new: false,
            },
            {
                level: 39,
                name: 'House',
                new: false,
            },
            {
                level: 40,
                name: 'Movie character',
                new: false,
            },
            {
                level: 41,
                name: 'Teapot',
                new: false,
            },
            {
                level: 42,
                name: 'Train',
                new: false,
            },
            {
                level: 43,
                name: 'Chicken',
                new: false,
            },
            {
                level: 44,
                name: 'Maze',
                new: false,
            },
            {
                level: 45,
                name: 'Beer',
                new: false,
            },
            {
                level: 46,
                name: 'Prize cup',
                new: false,
            },
            {
                level: 47,
                name: 'Game',
                new: false,
            },
            {
                level: 48,
                name: 'Clock',
                new: false,
            },
            {
                level: 49,
                name: 'Mouse',
                new: false,
            },
            {
                level: 50,
                name: 'Plug',
                new: false,
            },
            {
                level: 51,
                name: 'Phone',
                new: false,
            },
            {
                level: 52,
                name: 'Camera',
                new: false,
            },
            {
                level: 53,
                name: 'Creature',
                new: false,
            },
            {
                level: 54,
                name: 'Calendar',
                new: false,
            },
            {
                level: 55,
                name: 'Cocktail',
                new: false,
            },
            {
                level: 56,
                name: 'Movie character',
                new: false,
            },
            {
                level: 57,
                name: 'Bird',
                new: false,
            },
            {
                level: 58,
                name: 'Man',
                new: false,
            },
            {
                level: 59,
                name: 'Girl',
                new: false,
            },
            {
                level: 60,
                name: 'Flower',
                new: false,
            },
            {
                level: 61,
                name: 'Detective',
                new: false,
            },
            {
                level: 62,
                name: 'Whale',
                new: false,
            },
            {
                level: 63,
                name: 'Lock',
                new: false,
            },
            {
                level: 64,
                name: 'Mario',
                new: false,
            },
            {
                level: 65,
                name: 'Network signal',
                new: false,
            },
            {
                level: 66,
                name: 'Fish',
                new: false,
            },
            {
                level: 67,
                name: 'Smile',
                new: false,
            },
            {
                level: 68,
                name: 'Bunny',
                new: false,
            },
            {
                level: 69,
                name: 'Lion',
                new: false,
            },
            {
                level: 70,
                name: 'Lamp',
                new: false,
            },
            {
                level: 71,
                name: 'Rocket',
                new: false,
            },
            {
                level: 72,
                name: 'Man',
                new: false,
            },
            {
                level: 73,
                name: 'Bell',
                new: false,
            },
            {
                level: 74,
                name: 'Balloon',
                new: false,
            },
            {
                level: 75,
                name: 'Sportsman',
                new: false,
            },
            {
                level: 76,
                name: 'Apple',
                new: false,
            },
            {
                level: 77,
                name: 'Fish',
                new: false,
            },
            {
                level: 78,
                name: 'Eagle',
                new: false,
            },
            {
                level: 79,
                name: 'Ambulance',
                new: false,
            },
            {
                level: 80,
                name: 'Face',
                new: false,
            },
            {
                level: 81,
                name: 'Boat',
                new: false,
            },
            {
                level: 82,
                name: 'Pot',
                new: false,
            },
            {
                level: 83,
                name: 'Treble clef',
                new: false,
            },
            {
                level: 84,
                name: 'Hand Drill',
                new: false,
            },
            {
                level: 85,
                name: 'Helmet',
                new: false,
            },
            {
                level: 86,
                name: 'Panda',
                new: false,
            },
            {
                level: 87,
                name: 'Leaf',
                new: false,
            },
            {
                level: 88,
                name: 'Wolf',
                new: false,
            },
            {
                level: 89,
                name: 'Shoe',
                new: false,
            },
            {
                level: 90,
                name: 'Car',
                new: false,
            },
            {
                level: 91,
                name: 'Truck',
                new: false,
            },
            {
                level: 92,
                name: 'Battery',
                new: false,
            },
            {
                level: 93,
                name: 'Seahorse',
                new: false,
            },
            {
                level: 94,
                name: 'Camel',
                new: false,
            },
            {
                level: 95,
                name: 'Spaceship',
                new: false,
            },
            {
                level: 96,
                name: 'Dog',
                new: false,
            },
            {
                level: 97,
                name: 'Key',
                new: false,
            },
            {
                level: 98,
                name: 'Moose',
                new: false,
            },
            {
                level: 99,
                name: 'Duck',
                new: false,
            },
            {
                level: 100,
                name: 'Shoe',
                new: false,
            },
            {
                level: 101,
                name: 'Hammer',
                new: false,
            },
            {
                level: 102,
                name: 'Goat',
                new: false,
            },
            {
                level: 103,
                name: 'TV',
                new: false,
            },
            {
                level: 104,
                name: 'Truck',
                new: false,
            },
            {
                level: 105,
                name: 'Flower',
                new: false,
            },
            {
                level: 106,
                name: 'Bunny',
                new: false,
            },
            {
                level: 107,
                name: 'Flag',
                new: false,
            },
            {
                level: 108,
                name: 'Steamship',
                new: false,
            },
            {
                level: 109,
                name: 'Teapot',
                new: false,
            },
            {
                level: 110,
                name: 'Wine',
                new: false,
            },
            {
                level: 111,
                name: 'Kitten',
                new: false,
            },
            {
                level: 112,
                name: 'Hourglass',
                new: false,
            },
            {
                level: 113,
                name: 'TV',
                new: false,
            },
            {
                level: 114,
                name: 'Night',
                new: false,
            },
            {
                level: 115,
                name: 'Turtle',
                new: false,
            },
            {
                level: 116,
                name: 'Mask',
                new: false,
            },
            {
                level: 117,
                name: 'Elephant',
                new: false,
            },
            {
                level: 118,
                name: 'Prison',
                new: false,
            },
            {
                level: 119,
                name: 'House',
                new: false,
            },
            {
                level: 120,
                name: 'Tractor',
                new: false,
            },
            {
                level: 121,
                name: 'Leaf',
                new: false,
            },
            {
                level: 122,
                name: 'Clock',
                new: false,
            },
            {
                level: 123,
                name: 'Building',
                new: false,
            },
            {
                level: 124,
                name: 'Ship',
                new: false,
            },
            {
                level: 125,
                name: 'Movie character',
                new: false,
            },
            {
                level: 126,
                name: 'Christmas tree',
                new: false,
            },
            {
                level: 127,
                name: 'Pencil',
                new: false,
            },
            {
                level: 128,
                name: 'Burger',
                new: false,
            },
            {
                level: 129,
                name: 'Snake',
                new: false,
            },
            {
                level: 130,
                name: 'Football',
                new: false,
            },
            {
                level: 131,
                name: 'Wolf',
                new: false,
            },
            {
                level: 132,
                name: 'Dog',
                new: false,
            },
            {
                level: 133,
                name: 'Monster',
                new: false,
            },
            {
                level: 134,
                name: 'Smile',
                new: false,
            },
            {
                level: 135,
                name: 'Man',
                new: false,
            },
            {
                level: 136,
                name: 'Hourglass',
                new: false,
            },
            {
                level: 137,
                name: 'Skater',
                new: false,
            },
            {
                level: 138,
                name: 'Bird',
                new: false,
            },
            {
                level: 139,
                name: 'Tracks',
                new: false,
            },
            {
                level: 140,
                name: 'Tracks',
                new: false,
            },
            {
                level: 141,
                name: 'Crocodile',
                new: false,
            },
            {
                level: 142,
                name: 'Plane',
                new: false,
            },
            {
                level: 143,
                name: 'Chicken',
                new: false,
            },
            {
                level: 144,
                name: 'Skates',
                new: false,
            },
            {
                level: 145,
                name: 'Plane',
                new: false,
            },
            {
                level: 146,
                name: 'Dancer',
                new: false,
            },
            {
                level: 147,
                name: 'Face',
                new: false,
            },
            {
                level: 148,
                name: 'Camera',
                new: false,
            },
            {
                level: 149,
                name: 'Bird',
                new: false,
            },
            {
                level: 150,
                name: 'Stairs',
                new: false,
            },
            {
                level: 151,
                name: 'House',
                new: false,
            },
            {
                level: 152,
                name: 'Fool',
                new: false,
            },
            {
                level: 153,
                name: 'Note',
                new: false,
            },
            {
                level: 154,
                name: 'Heart',
                new: false,
            },
            {
                level: 155,
                name: 'Sewing-machine',
                new: false,
            },
            {
                level: 156,
                name: 'Bear',
                new: false,
            },
            {
                level: 157,
                name: 'Ship',
                new: false,
            },
            {
                level: 158,
                name: 'Eagle',
                new: false,
            },
            {
                level: 159,
                name: 'Radio',
                new: false,
            },
            {
                level: 160,
                name: 'Skull',
                new: false,
            },
            {
                level: 161,
                name: 'Tower',
                new: false,
            },
            {
                level: 162,
                name: 'Creature',
                new: false,
            },
        ]
    },
    {
        level: 3,
        name: 'Bushi',
        levels: [
            {
                level: 1,
                name: 'Mask',
                new: false,
            },
            {
                level: 2,
                name: 'Poison',
                new: false,
            },
            {
                level: 3,
                name: 'Fairy',
                new: false,
            },
            {
                level: 4,
                name: 'Seahorse',
                new: false,
            },
            {
                level: 5,
                name: 'Box',
                new: false,
            },
            {
                level: 6,
                name: 'Swan',
                new: false,
            },
            {
                level: 7,
                name: 'Reader',
                new: false,
            },
            {
                level: 8,
                name: 'Bug',
                new: false,
            },
            {
                level: 9,
                name: 'Worm',
                new: false,
            },
            {
                level: 10,
                name: 'Camel',
                new: false,
            },
            {
                level: 11,
                name: 'Boat',
                new: false,
            },
            {
                level: 12,
                name: 'Fight',
                new: false,
            },
            {
                level: 13,
                name: 'Bow',
                new: false,
            },
            {
                level: 14,
                name: 'Watering-can',
                new: false,
            },
            {
                level: 15,
                name: 'Church',
                new: false,
            },
            {
                level: 16,
                name: 'Note',
                new: false,
            },
            {
                level: 17,
                name: 'Mushroom',
                new: false,
            },
            {
                level: 18,
                name: 'Meal',
                new: false,
            },
            {
                level: 19,
                name: 'Island',
                new: false,
            },
            {
                level: 20,
                name: 'Egypt',
                new: false,
            },
            {
                level: 21,
                name: 'Glasses',
                new: false,
            },
            {
                level: 22,
                name: 'Socket',
                new: false,
            },
            {
                level: 23,
                name: 'Boot',
                new: false,
            },
            {
                level: 24,
                name: 'Sewing-machine',
                new: false,
            },
            {
                level: 25,
                name: 'Comb',
                new: false,
            },
            {
                level: 26,
                name: 'Car',
                new: false,
            },
            {
                level: 27,
                name: 'Actor',
                new: false,
            },
            {
                level: 28,
                name: 'Ornament',
                new: false,
            },
            {
                level: 29,
                name: 'Stove',
                new: false,
            },
            {
                level: 30,
                name: 'Man',
                new: false,
            },
            {
                level: 31,
                name: 'Bus',
                new: false,
            },
            {
                level: 32,
                name: 'Fur Seal',
                new: false,
            },
            {
                level: 33,
                name: 'Cat',
                new: false,
            },
            {
                level: 34,
                name: 'Ball',
                new: false,
            },
            {
                level: 35,
                name: 'Tank',
                new: false,
            },
            {
                level: 36,
                name: 'Leaf',
                new: false,
            },
            {
                level: 37,
                name: 'Parrot',
                new: false,
            },
            {
                level: 38,
                name: 'Band',
                new: false,
            },
            {
                level: 39,
                name: 'Rocket',
                new: false,
            },
            {
                level: 40,
                name: 'Cat',
                new: false,
            },
            {
                level: 41,
                name: 'Horse',
                new: false,
            },
            {
                level: 42,
                name: 'Eagle',
                new: false,
            },
            {
                level: 43,
                name: 'Dog',
                new: false,
            },
            {
                level: 44,
                name: 'Scales',
                new: false,
            },
            {
                level: 45,
                name: 'Lily',
                new: false,
            },
            {
                level: 46,
                name: 'Star',
                new: false,
            },
            {
                level: 47,
                name: 'Lizard',
                new: false,
            },
            {
                level: 48,
                name: 'Tiger',
                new: false,
            },
            {
                level: 49,
                name: 'Snail',
                new: false,
            },
            {
                level: 50,
                name: 'Man',
                new: false,
            },
            {
                level: 51,
                name: 'Cartoon character',
                new: false,
            },
            {
                level: 52,
                name: 'Christmas-tree ball',
                new: false,
            },
            {
                level: 53,
                name: 'Puppy',
                new: false,
            },
            {
                level: 54,
                name: 'Crown',
                new: false,
            },
            {
                level: 55,
                name: 'Sofa',
                new: false,
            },
            {
                level: 56,
                name: 'Pig',
                new: false,
            },
            {
                level: 57,
                name: 'Smile',
                new: false,
            },
            {
                level: 58,
                name: 'Pirate flag',
                new: false,
            },
            {
                level: 59,
                name: 'Cow',
                new: false,
            },
            {
                level: 60,
                name: 'Cartoon character',
                new: false,
            },
            {
                level: 61,
                name: 'Mouse',
                new: false,
            },
            {
                level: 62,
                name: 'Shoe',
                new: false,
            },
            {
                level: 63,
                name: 'Island',
                new: false,
            },
            {
                level: 64,
                name: 'Dog',
                new: false,
            },
            {
                level: 65,
                name: 'Guitar',
                new: false,
            },
            {
                level: 66,
                name: 'Dragonfly',
                new: false,
            },
            {
                level: 67,
                name: 'Fish',
                new: false,
            },
            {
                level: 68,
                name: 'Sagittarius',
                new: false,
            },
            {
                level: 69,
                name: 'Bike',
                new: false,
            },
            {
                level: 70,
                name: 'Fish',
                new: false,
            },
            {
                level: 71,
                name: 'Fairy tale character',
                new: false,
            },
            {
                level: 72,
                name: 'Koala',
                new: false,
            },
            {
                level: 73,
                name: 'House',
                new: false,
            },
            {
                level: 74,
                name: 'Criminal',
                new: false,
            },
            {
                level: 75,
                name: 'Scissors',
                new: false,
            },
            {
                level: 76,
                name: 'Sportsman',
                new: false,
            },
            {
                level: 77,
                name: 'Duck',
                new: false,
            },
            {
                level: 78,
                name: 'Pirate flag',
                new: false,
            },
            {
                level: 79,
                name: 'No smoking',
                new: false,
            },
            {
                level: 80,
                name: 'Gun',
                new: false,
            },
            {
                level: 81,
                name: 'Thumb Up',
                new: false,
            },
            {
                level: 82,
                name: 'Aliens',
                new: false,
            },
            {
                level: 83,
                name: 'Car',
                new: false,
            },
            {
                level: 84,
                name: 'Skull',
                new: false,
            },
            {
                level: 85,
                name: 'Steamship',
                new: false,
            },
            {
                level: 86,
                name: 'Hedgehog',
                new: false,
            },
            {
                level: 87,
                name: 'Car',
                new: false,
            },
            {
                level: 88,
                name: 'Duck',
                new: false,
            },
            {
                level: 89,
                name: 'Sheep',
                new: false,
            },
            {
                level: 90,
                name: 'Dinosaur',
                new: false,
            },
            {
                level: 91,
                name: 'Candles',
                new: false,
            },
            {
                level: 92,
                name: 'Whale',
                new: false,
            },
            {
                level: 93,
                name: 'Panda',
                new: false,
            },
            {
                level: 94,
                name: 'Cow',
                new: false,
            },
            {
                level: 95,
                name: 'Flower',
                new: false,
            },
            {
                level: 96,
                name: 'Snail',
                new: false,
            },
            {
                level: 97,
                name: 'Bird',
                new: false,
            },
            {
                level: 98,
                name: 'Grasshopper',
                new: false,
            },
            {
                level: 99,
                name: 'Bear',
                new: false,
            },
            {
                level: 100,
                name: 'Phone',
                new: false,
            },
            {
                level: 101,
                name: 'Camel',
                new: false,
            },
            {
                level: 102,
                name: 'Dog',
                new: false,
            },
            {
                level: 103,
                name: 'Leaf',
                new: false,
            },
            {
                level: 104,
                name: 'Spruce',
                new: false,
            },
            {
                level: 105,
                name: 'Bat',
                new: false,
            },
            {
                level: 106,
                name: 'Ram',
                new: false,
            },
            {
                level: 107,
                name: 'Elephant',
                new: false,
            },
            {
                level: 108,
                name: 'Executioner',
                new: false,
            },
            {
                level: 109,
                name: 'Half moon',
                new: false,
            },
            {
                level: 110,
                name: 'Fool',
                new: false,
            },
            {
                level: 111,
                name: 'Fox',
                new: false,
            },
            {
                level: 112,
                name: 'Piggy bank',
                new: false,
            },
            {
                level: 113,
                name: 'Ram',
                new: false,
            },
            {
                level: 114,
                name: 'Bike',
                new: false,
            },
            {
                level: 115,
                name: 'Mountain dweller',
                new: false,
            },
            {
                level: 116,
                name: 'Cannon',
                new: false,
            },
            {
                level: 117,
                name: 'Lock',
                new: false,
            },
            {
                level: 118,
                name: 'Christmas tree',
                new: false,
            },
            {
                level: 119,
                name: 'Woman',
                new: false,
            },
            {
                level: 120,
                name: 'Walrus',
                new: false,
            },
            {
                level: 121,
                name: 'Key',
                new: false,
            },
            {
                level: 122,
                name: 'Bomb',
                new: false,
            },
            {
                level: 123,
                name: 'Cannon',
                new: false,
            },
            {
                level: 124,
                name: 'Cart',
                new: false,
            },
            {
                level: 125,
                name: 'Vegetable',
                new: false,
            },
            {
                level: 126,
                name: 'Horse',
                new: false,
            },
            {
                level: 127,
                name: 'Octopus',
                new: false,
            },
            {
                level: 128,
                name: 'Snail',
                new: false,
            },
            {
                level: 129,
                name: 'Parrot',
                new: false,
            },
            {
                level: 130,
                name: 'Duck',
                new: false,
            },
            {
                level: 131,
                name: 'Jellyfish',
                new: false,
            },
            {
                level: 132,
                name: 'Snake',
                new: false,
            },
            {
                level: 133,
                name: 'Wine',
                new: false,
            },
            {
                level: 134,
                name: 'Headset',
                new: false,
            },
            {
                level: 135,
                name: 'Cow',
                new: false,
            },
            {
                level: 136,
                name: 'Dinosaur',
                new: false,
            },
            {
                level: 137,
                name: 'Cherry',
                new: false,
            },
            {
                level: 138,
                name: 'Plane',
                new: false,
            },
            {
                level: 139,
                name: 'Dolphin',
                new: false,
            },
            {
                level: 140,
                name: 'Ram',
                new: false,
            },
            {
                level: 141,
                name: 'Bird',
                new: false,
            },
            {
                level: 142,
                name: 'Skull',
                new: false,
            },
            {
                level: 143,
                name: 'Horse',
                new: false,
            },
            {
                level: 144,
                name: 'Magic lamp',
                new: false,
            },
            {
                level: 145,
                name: 'Cancer',
                new: false,
            },
            {
                level: 146,
                name: 'Plug',
                new: false,
            },
            {
                level: 147,
                name: 'Robot',
                new: false,
            },
            {
                level: 148,
                name: 'Camera',
                new: false,
            },
            {
                level: 149,
                name: 'Face',
                new: false,
            },
            {
                level: 150,
                name: 'Boat',
                new: false,
            },
            {
                level: 151,
                name: 'Scissors',
                new: false,
            },
            {
                level: 152,
                name: 'Creature',
                new: false,
            },
            {
                level: 153,
                name: 'Music',
                new: false,
            },
            {
                level: 154,
                name: 'Ice cream',
                new: false,
            },
            {
                level: 155,
                name: 'Beach',
                new: false,
            },
            {
                level: 156,
                name: 'Crab',
                new: false,
            },
            {
                level: 157,
                name: 'Puppy',
                new: false,
            },
            {
                level: 158,
                name: 'Snowman',
                new: false,
            },
            {
                level: 159,
                name: 'Bug',
                new: false,
            },
            {
                level: 160,
                name: 'Mouse',
                new: false,
            },
            {
                level: 161,
                name: 'Pig',
                new: false,
            },
            {
                level: 162,
                name: 'Owl',
                new: false,
            },
        ]
    },
    {
        level: 4,
        name: 'Shindbi',
        levels: [
            {
                level: 1,
                name: 'Donkey',
                new: false,
            },
            {
                level: 2,
                name: 'Squirrel',
                new: false,
            },
            {
                level: 3,
                name: 'MTV',
                new: false,
            },
            {
                level: 4,
                name: 'Sportsman',
                new: false,
            },
            {
                level: 5,
                name: 'Swan',
                new: false,
            },
            {
                level: 6,
                name: 'Fish',
                new: false,
            },
            {
                level: 7,
                name: 'Deer',
                new: false,
            },
            {
                level: 8,
                name: 'Football',
                new: false,
            },
            {
                level: 9,
                name: 'Nutcracker',
                new: false,
            },
            {
                level: 10,
                name: 'Crocodile',
                new: false,
            },
            {
                level: 11,
                name: 'Snake',
                new: false,
            },
            {
                level: 12,
                name: 'Chess knight',
                new: false,
            },
            {
                level: 13,
                name: 'Dog',
                new: false,
            },
            {
                level: 14,
                name: 'Rooster',
                new: false,
            },
            {
                level: 15,
                name: 'Tennis',
                new: false,
            },
            {
                level: 16,
                name: 'Samovar',
                new: false,
            },
            {
                level: 17,
                name: 'Snowmobile',
                new: false,
            },
            {
                level: 18,
                name: 'Hotdog',
                new: false,
            },
            {
                level: 19,
                name: 'Crow',
                new: false,
            },
            {
                level: 20,
                name: 'Dinosaur',
                new: false,
            },
            {
                level: 21,
                name: 'Skull',
                new: false,
            },
            {
                level: 22,
                name: 'Rotator',
                new: false,
            },
            {
                level: 23,
                name: 'Fire Truck',
                new: false,
            },
            {
                level: 24,
                name: 'Medicine',
                new: false,
            },
            {
                level: 25,
                name: 'Puppy',
                new: false,
            },
            {
                level: 26,
                name: 'Leaf',
                new: false,
            },
            {
                level: 27,
                name: 'Bomb',
                new: false,
            },
            {
                level: 28,
                name: 'Satellite dish',
                new: false,
            },
            {
                level: 29,
                name: 'Mouse',
                new: false,
            },
            {
                level: 30,
                name: 'Taxi',
                new: false,
            },
            {
                level: 31,
                name: 'Boy',
                new: false,
            },
            {
                level: 32,
                name: 'Gun',
                new: false,
            },
            {
                level: 33,
                name: 'Creature',
                new: false,
            },
            {
                level: 34,
                name: 'Buffalo',
                new: false,
            },
            {
                level: 35,
                name: 'Church',
                new: false,
            },
            {
                level: 36,
                name: 'Mushrooms',
                new: false,
            },
            {
                level: 37,
                name: 'Horse',
                new: false,
            },
            {
                level: 38,
                name: 'Beer',
                new: false,
            },
            {
                level: 39,
                name: 'Bird',
                new: false,
            },
            {
                level: 40,
                name: 'House',
                new: false,
            },
            {
                level: 41,
                name: 'Turtle',
                new: false,
            },
            {
                level: 42,
                name: 'Dragon',
                new: false,
            },
            {
                level: 43,
                name: 'Horse',
                new: false,
            },
            {
                level: 44,
                name: 'Dog',
                new: false,
            },
            {
                level: 45,
                name: 'Fool',
                new: false,
            },
            {
                level: 46,
                name: 'Hedgehog',
                new: false,
            },
            {
                level: 47,
                name: 'Ambulance',
                new: false,
            },
            {
                level: 48,
                name: 'Mace',
                new: false,
            },
            {
                level: 49,
                name: 'Map',
                new: false,
            },
            {
                level: 50,
                name: 'Pump',
                new: false,
            },
            {
                level: 51,
                name: 'Hippo',
                new: false,
            },
            {
                level: 52,
                name: 'Tiger',
                new: false,
            },
            {
                level: 53,
                name: 'Palette',
                new: false,
            },
            {
                level: 54,
                name: 'Cup',
                new: false,
            },
            {
                level: 55,
                name: 'Bow',
                new: false,
            },
            {
                level: 56,
                name: 'Fish',
                new: false,
            },
            {
                level: 57,
                name: 'Candle',
                new: false,
            },
            {
                level: 58,
                name: 'Flower',
                new: false,
            },
            {
                level: 59,
                name: 'Chess',
                new: false,
            },
            {
                level: 60,
                name: 'Bird',
                new: false,
            },
            {
                level: 61,
                name: 'Luggage',
                new: false,
            },
            {
                level: 62,
                name: 'Pegasus',
                new: false,
            },
            {
                level: 63,
                name: 'Iron',
                new: false,
            },
            {
                level: 64,
                name: 'Car',
                new: false,
            },
            {
                level: 65,
                name: 'Horse',
                new: false,
            },
            {
                level: 66,
                name: 'Medicine',
                new: false,
            },
            {
                level: 67,
                name: 'Bicycle',
                new: false,
            },
            {
                level: 68,
                name: 'Octopus',
                new: false,
            },
            {
                level: 69,
                name: 'Paper',
                new: false,
            },
            {
                level: 70,
                name: 'Woodpecker',
                new: false,
            },
            {
                level: 71,
                name: 'Teapot',
                new: false,
            },
            {
                level: 72,
                name: 'Basketball',
                new: false,
            },
            {
                level: 73,
                name: 'Well',
                new: false,
            },
            {
                level: 74,
                name: 'Bunny',
                new: false,
            },
            {
                level: 75,
                name: 'Dog',
                new: false,
            },
            {
                level: 76,
                name: 'Scales',
                new: false,
            },
            {
                level: 77,
                name: 'Riding',
                new: false,
            },
            {
                level: 78,
                name: 'Snail',
                new: false,
            },
            {
                level: 79,
                name: 'Cat',
                new: false,
            },
            {
                level: 80,
                name: 'Wild Boar',
                new: false,
            },
            {
                level: 81,
                name: 'Shovel',
                new: false,
            },
            {
                level: 82,
                name: 'Band',
                new: false,
            },
            {
                level: 83,
                name: 'Snowman',
                new: false,
            },
            {
                level: 84,
                name: 'Santa Claus',
                new: false,
            },
            {
                level: 85,
                name: 'Lizard',
                new: false,
            },
            {
                level: 86,
                name: 'Moose',
                new: false,
            },
            {
                level: 87,
                name: 'Rider',
                new: false,
            },
            {
                level: 88,
                name: 'Plane',
                new: false,
            },
            {
                level: 89,
                name: 'Donkey',
                new: false,
            },
            {
                level: 90,
                name: 'Cat',
                new: false,
            },
            {
                level: 91,
                name: 'Mushrooms',
                new: false,
            },
            {
                level: 92,
                name: 'Skunk',
                new: false,
            },
            {
                level: 93,
                name: 'Bunny',
                new: false,
            },
            {
                level: 94,
                name: 'Bird',
                new: false,
            },
            {
                level: 95,
                name: 'Dog',
                new: false,
            },
            {
                level: 96,
                name: 'Mouse',
                new: false,
            },
            {
                level: 97,
                name: 'Train',
                new: false,
            },
            {
                level: 98,
                name: 'Hell',
                new: false,
            },
            {
                level: 99,
                name: 'Wave runner',
                new: false,
            },
            {
                level: 100,
                name: 'Pelican',
                new: false,
            },
            {
                level: 101,
                name: 'Bulldozer',
                new: false,
            },
            {
                level: 102,
                name: 'Crow',
                new: false,
            },
            {
                level: 103,
                name: 'Cat',
                new: false,
            },
            {
                level: 104,
                name: 'Truck',
                new: false,
            },
            {
                level: 105,
                name: 'Girl',
                new: false,
            },
            {
                level: 106,
                name: 'Traffic lights',
                new: false,
            },
            {
                level: 107,
                name: 'Crab',
                new: false,
            },
            {
                level: 108,
                name: 'Eagle',
                new: false,
            },
            {
                level: 109,
                name: 'Rose',
                new: false,
            },
            {
                level: 110,
                name: 'Apple',
                new: false,
            },
            {
                level: 111,
                name: 'Camera',
                new: false,
            },
            {
                level: 112,
                name: 'Windsurfing',
                new: false,
            },
            {
                level: 113,
                name: 'Cat',
                new: false,
            },
            {
                level: 114,
                name: 'Knight',
                new: false,
            },
            {
                level: 115,
                name: 'Turtle',
                new: false,
            },
            {
                level: 116,
                name: 'Vegetable',
                new: false,
            },
            {
                level: 117,
                name: 'Tower',
                new: false,
            },
            {
                level: 118,
                name: 'Road',
                new: false,
            },
            {
                level: 119,
                name: 'Cartoon character',
                new: false,
            },
            {
                level: 120,
                name: 'Cancer',
                new: false,
            },
            {
                level: 121,
                name: 'Ant',
                new: false,
            },
            {
                level: 122,
                name: 'Owl',
                new: false,
            },
            {
                level: 123,
                name: 'Cocktail',
                new: false,
            },
            {
                level: 124,
                name: 'Dog',
                new: false,
            },
            {
                level: 125,
                name: 'Palm tree',
                new: false,
            },
            {
                level: 126,
                name: 'Camera',
                new: false,
            },
            {
                level: 127,
                name: 'American indian',
                new: false,
            },
            {
                level: 128,
                name: 'Seahorse',
                new: false,
            },
            {
                level: 129,
                name: 'Flower',
                new: false,
            },
            {
                level: 130,
                name: 'Candle',
                new: false,
            },
            {
                level: 131,
                name: 'Car',
                new: false,
            },
            {
                level: 132,
                name: 'Tractor',
                new: false,
            },
            {
                level: 133,
                name: 'Candles',
                new: false,
            },
            {
                level: 134,
                name: 'Campfire',
                new: false,
            },
            {
                level: 135,
                name: 'Fox',
                new: false,
            },
            {
                level: 136,
                name: 'Shelf',
                new: false,
            },
            {
                level: 137,
                name: 'Cat',
                new: false,
            },
            {
                level: 138,
                name: 'Friends',
                new: false,
            },
            {
                level: 139,
                name: 'Dice',
                new: false,
            },
            {
                level: 140,
                name: 'Sweater',
                new: false,
            },
            {
                level: 141,
                name: 'Bug',
                new: false,
            },
            {
                level: 142,
                name: 'Crow',
                new: false,
            },
            {
                level: 143,
                name: 'Bug',
                new: false,
            },
            {
                level: 144,
                name: 'Caterpillar',
                new: false,
            },
            {
                level: 145,
                name: 'Actor',
                new: false,
            },
            {
                level: 146,
                name: 'Dolphin',
                new: false,
            },
            {
                level: 147,
                name: 'Street',
                new: false,
            },
            {
                level: 148,
                name: 'House',
                new: false,
            },
            {
                level: 149,
                name: 'Man',
                new: false,
            },
            {
                level: 150,
                name: 'Mouses',
                new: false,
            },
            {
                level: 151,
                name: 'Candy',
                new: false,
            },
            {
                level: 152,
                name: 'Rifle',
                new: false,
            },
            {
                level: 153,
                name: 'Dog',
                new: false,
            },
            {
                level: 154,
                name: 'Bowling',
                new: false,
            },
            {
                level: 155,
                name: 'Car',
                new: false,
            },
            {
                level: 156,
                name: 'Movie character',
                new: false,
            },
            {
                level: 157,
                name: 'Dice',
                new: false,
            },
            {
                level: 158,
                name: 'Butterfly',
                new: false,
            },
            {
                level: 159,
                name: 'Guns',
                new: false,
            },
            {
                level: 160,
                name: 'Pumpkin',
                new: false,
            },
            {
                level: 161,
                name: 'Street',
                new: false,
            },
            {
                level: 162,
                name: 'Couple',
                new: false,
            },
        ]
    },
    {
        level: 5,
        name: 'Ronin',
        levels: [
            {
                level: 1,
                name: 'Plane',
                new: false,
            },
            {
                level: 2,
                name: 'Record player',
                new: false,
            },
            {
                level: 3,
                name: 'Carriage',
                new: false,
            },
            {
                level: 4,
                name: 'Bus',
                new: false,
            },
            {
                level: 5,
                name: 'Woman',
                new: false,
            },
            {
                level: 6,
                name: 'Genie',
                new: false,
            },
            {
                level: 7,
                name: 'Elephant',
                new: false,
            },
            {
                level: 8,
                name: 'Vegetable',
                new: false,
            },
            {
                level: 9,
                name: 'Butterfly',
                new: false,
            },
            {
                level: 10,
                name: 'Painter',
                new: false,
            },
            {
                level: 11,
                name: 'Reading',
                new: false,
            },
            {
                level: 12,
                name: 'Crocodile',
                new: false,
            },
            {
                level: 13,
                name: 'Flower',
                new: false,
            },
            {
                level: 14,
                name: 'Clock',
                new: false,
            },
            {
                level: 15,
                name: 'Chimney sweep',
                new: false,
            },
            {
                level: 16,
                name: 'Genie',
                new: false,
            },
            {
                level: 17,
                name: 'Dragon',
                new: false,
            },
            {
                level: 18,
                name: 'Spider',
                new: false,
            },
            {
                level: 19,
                name: 'Rhino',
                new: false,
            },
            {
                level: 20,
                name: 'Flower',
                new: false,
            },
            {
                level: 21,
                name: 'Sign',
                new: false,
            },
            {
                level: 22,
                name: 'Roman',
                new: false,
            },
            {
                level: 23,
                name: 'Shield',
                new: false,
            },
            {
                level: 24,
                name: 'Flowers',
                new: false,
            },
            {
                level: 25,
                name: 'Temple',
                new: false,
            },
            {
                level: 26,
                name: 'Fish',
                new: false,
            },
            {
                level: 27,
                name: 'Fancy cake',
                new: false,
            },
            {
                level: 28,
                name: 'Bulb',
                new: false,
            },
            {
                level: 29,
                name: 'Hedgehog',
                new: false,
            },
            {
                level: 30,
                name: 'Frog',
                new: false,
            },
            {
                level: 31,
                name: 'Cat',
                new: false,
            },
            {
                level: 32,
                name: 'Airport',
                new: false,
            },
            {
                level: 33,
                name: 'Snake',
                new: false,
            },
            {
                level: 34,
                name: 'Dog',
                new: false,
            },
            {
                level: 35,
                name: 'Lantern',
                new: false,
            },
            {
                level: 36,
                name: 'Goose',
                new: false,
            },
            {
                level: 37,
                name: 'Little girl',
                new: false,
            },
            {
                level: 38,
                name: 'Bicyclist',
                new: false,
            },
            {
                level: 39,
                name: 'Spaceman',
                new: false,
            },
            {
                level: 40,
                name: 'Butterfly',
                new: false,
            },
            {
                level: 41,
                name: 'Animals',
                new: false,
            },
            {
                level: 42,
                name: 'Fish',
                new: false,
            },
            {
                level: 43,
                name: 'Couple',
                new: false,
            },
            {
                level: 44,
                name: 'Reader',
                new: false,
            },
            {
                level: 45,
                name: 'Dog',
                new: false,
            },
            {
                level: 46,
                name: 'Penguin',
                new: false,
            },
            {
                level: 47,
                name: 'Girl',
                new: false,
            },
            {
                level: 48,
                name: 'Candles',
                new: false,
            },
            {
                level: 49,
                name: 'Half moon',
                new: false,
            },
            {
                level: 50,
                name: 'Glasses',
                new: false,
            },
            {
                level: 51,
                name: 'Angel',
                new: false,
            },
            {
                level: 52,
                name: 'Dog',
                new: false,
            },
            {
                level: 53,
                name: 'Corn',
                new: false,
            },
            {
                level: 54,
                name: 'Guitar',
                new: false,
            },
            {
                level: 55,
                name: 'Goldfish',
                new: false,
            },
            {
                level: 56,
                name: 'Girl',
                new: false,
            },
            {
                level: 57,
                name: 'Snowman',
                new: false,
            },
            {
                level: 58,
                name: 'Death',
                new: false,
            },
            {
                level: 59,
                name: 'Elephant',
                new: false,
            },
            {
                level: 60,
                name: 'Nurse',
                new: false,
            },
            {
                level: 61,
                name: 'Mother',
                new: false,
            },
            {
                level: 62,
                name: 'Drummer',
                new: false,
            },
            {
                level: 63,
                name: 'Girl',
                new: false,
            },
            {
                level: 64,
                name: 'Fairy tale character',
                new: false,
            },
            {
                level: 65,
                name: 'Baby carriage',
                new: false,
            },
            {
                level: 66,
                name: 'Map',
                new: false,
            },
            {
                level: 67,
                name: 'Puppy',
                new: false,
            },
            {
                level: 68,
                name: 'Fox',
                new: false,
            },
            {
                level: 69,
                name: 'Man',
                new: false,
            },
            {
                level: 70,
                name: 'King',
                new: false,
            },
            {
                level: 71,
                name: 'Apple',
                new: false,
            },
            {
                level: 72,
                name: 'Tracks',
                new: false,
            },
            {
                level: 73,
                name: 'Dog',
                new: false,
            },
            {
                level: 74,
                name: 'Yoke',
                new: false,
            },
            {
                level: 75,
                name: 'People',
                new: false,
            },
            {
                level: 76,
                name: 'Lock',
                new: false,
            },
            {
                level: 77,
                name: 'Helicopter',
                new: false,
            },
            {
                level: 78,
                name: 'Vegetable',
                new: false,
            },
            {
                level: 79,
                name: 'Train',
                new: false,
            },
            {
                level: 80,
                name: 'Mouse',
                new: false,
            },
            {
                level: 81,
                name: 'Dolphin',
                new: false,
            },
            {
                level: 82,
                name: 'Flasks',
                new: false,
            },
            {
                level: 83,
                name: 'Bear',
                new: false,
            },
            {
                level: 84,
                name: 'Bunny',
                new: false,
            },
            {
                level: 85,
                name: 'Crawfish',
                new: false,
            },
            {
                level: 86,
                name: 'Fly',
                new: false,
            },
            {
                level: 87,
                name: 'Bird',
                new: false,
            },
            {
                level: 88,
                name: 'Dance',
                new: false,
            },
            {
                level: 89,
                name: 'Sportsman',
                new: false,
            },
            {
                level: 90,
                name: 'Gift',
                new: false,
            },
            {
                level: 91,
                name: 'Mushrooms',
                new: false,
            },
            {
                level: 92,
                name: 'Squirrel',
                new: false,
            },
            {
                level: 93,
                name: 'Camel',
                new: false,
            },
            {
                level: 94,
                name: 'Chair',
                new: false,
            },
            {
                level: 95,
                name: 'Teapot',
                new: false,
            },
            {
                level: 96,
                name: 'Ape',
                new: false,
            },
            {
                level: 97,
                name: 'Car',
                new: false,
            },
            {
                level: 98,
                name: 'Bear',
                new: false,
            },
            {
                level: 99,
                name: 'Weathercock',
                new: false,
            },
            {
                level: 100,
                name: 'Ostrich',
                new: false,
            },
            {
                level: 101,
                name: 'Horse',
                new: false,
            },
            {
                level: 102,
                name: 'Man',
                new: false,
            },
            {
                level: 103,
                name: 'Bear',
                new: false,
            },
            {
                level: 104,
                name: 'Crane',
                new: false,
            },
            {
                level: 105,
                name: 'House',
                new: false,
            },
            {
                level: 106,
                name: 'Guitar',
                new: false,
            },
            {
                level: 107,
                name: 'Eagle',
                new: false,
            },
            {
                level: 108,
                name: 'Truck',
                new: false,
            },
            {
                level: 109,
                name: 'Cartoon character',
                new: false,
            },
            {
                level: 110,
                name: 'Mermaid',
                new: false,
            },
            {
                level: 111,
                name: 'Rhino',
                new: false,
            },
            {
                level: 112,
                name: 'Heart',
                new: false,
            },
            {
                level: 113,
                name: 'Bowling',
                new: false,
            },
            {
                level: 114,
                name: 'Kitten',
                new: false,
            },
            {
                level: 115,
                name: 'Crow',
                new: false,
            },
            {
                level: 116,
                name: 'Ship',
                new: false,
            },
            {
                level: 117,
                name: 'Treble clef',
                new: false,
            },
            {
                level: 118,
                name: 'Projector',
                new: false,
            },
            {
                level: 119,
                name: 'Rider',
                new: false,
            },
            {
                level: 120,
                name: 'Port',
                new: false,
            },
            {
                level: 121,
                name: 'Plum',
                new: false,
            },
            {
                level: 122,
                name: 'Candle',
                new: false,
            },
            {
                level: 123,
                name: 'Vegetable',
                new: false,
            },
            {
                level: 124,
                name: 'Girl',
                new: false,
            },
            {
                level: 125,
                name: 'Fish',
                new: false,
            },
            {
                level: 126,
                name: 'Bunny',
                new: false,
            },
            {
                level: 127,
                name: 'Donkey',
                new: false,
            },
            {
                level: 128,
                name: 'Symbol',
                new: false,
            },
            {
                level: 129,
                name: 'Cartoon character',
                new: false,
            },
            {
                level: 130,
                name: 'Dragon',
                new: false,
            },
            {
                level: 131,
                name: 'Woman',
                new: false,
            },
            {
                level: 132,
                name: 'Man',
                new: false,
            },
            {
                level: 133,
                name: 'Weathercock',
                new: false,
            },
            {
                level: 134,
                name: 'Rhino',
                new: false,
            },
            {
                level: 135,
                name: 'Cat',
                new: false,
            },
            {
                level: 136,
                name: 'Cat',
                new: false,
            },
            {
                level: 137,
                name: 'Clown',
                new: false,
            },
            {
                level: 138,
                name: 'Minotaur',
                new: false,
            },
            {
                level: 139,
                name: 'Crocodile',
                new: false,
            },
            {
                level: 140,
                name: 'Travellers',
                new: false,
            },
            {
                level: 141,
                name: 'Wizard',
                new: false,
            },
            {
                level: 142,
                name: 'Frog',
                new: false,
            },
            {
                level: 143,
                name: 'Girl',
                new: false,
            },
            {
                level: 144,
                name: 'Face',
                new: false,
            },
            {
                level: 145,
                name: 'Couple',
                new: false,
            },
            {
                level: 146,
                name: 'Car',
                new: false,
            },
            {
                level: 147,
                name: 'Bunny',
                new: false,
            },
            {
                level: 148,
                name: 'Tank',
                new: false,
            },
            {
                level: 149,
                name: 'Pond',
                new: false,
            },
            {
                level: 150,
                name: 'Butterfly',
                new: false,
            },
            {
                level: 151,
                name: 'Creature',
                new: false,
            },
            {
                level: 152,
                name: 'Man',
                new: false,
            },
            {
                level: 153,
                name: 'Rider',
                new: false,
            },
            {
                level: 154,
                name: 'Aeronaut',
                new: false,
            },
            {
                level: 155,
                name: 'Frog',
                new: false,
            },
            {
                level: 156,
                name: 'Dragon',
                new: false,
            },
            {
                level: 157,
                name: 'Cat',
                new: false,
            },
            {
                level: 158,
                name: 'Santa Claus',
                new: false,
            },
            {
                level: 159,
                name: 'Woman',
                new: false,
            },
            {
                level: 160,
                name: 'Ship',
                new: false,
            },
            {
                level: 161,
                name: 'Rooster',
                new: false,
            },
            {
                level: 162,
                name: 'King',
                new: false,
            },
        ]
    },
    {
        level: 6,
        name: 'Samurai',
        levels: [
            {
                level: 1,
                name: 'Dog',
                new: false,
            },
            {
                level: 2,
                name: 'Cartoon character',
                new: false,
            },
            {
                level: 3,
                name: 'Acorn',
                new: false,
            },
            {
                level: 4,
                name: 'Ape',
                new: false,
            },
            {
                level: 5,
                name: 'Satellite dish',
                new: false,
            },
            {
                level: 6,
                name: 'Dog',
                new: false,
            },
            {
                level: 7,
                name: 'Fur Seal',
                new: false,
            },
            {
                level: 8,
                name: 'Director',
                new: false,
            },
            {
                level: 9,
                name: 'Chipmunk',
                new: false,
            },
            {
                level: 10,
                name: 'Couple',
                new: false,
            },
            {
                level: 11,
                name: 'Panther',
                new: false,
            },
            {
                level: 12,
                name: 'Frog',
                new: false,
            },
            {
                level: 13,
                name: 'Cats',
                new: false,
            },
            {
                level: 14,
                name: 'Stork',
                new: false,
            },
            {
                level: 15,
                name: 'Killer',
                new: false,
            },
            {
                level: 16,
                name: 'Nurse',
                new: false,
            },
            {
                level: 17,
                name: 'Cartoon character',
                new: false,
            },
            {
                level: 18,
                name: 'Celebration',
                new: false,
            },
            {
                level: 19,
                name: 'Moose',
                new: false,
            },
            {
                level: 20,
                name: 'Granny',
                new: false,
            },
            {
                level: 21,
                name: 'Prize',
                new: false,
            },
            {
                level: 22,
                name: 'Pig',
                new: false,
            },
            {
                level: 23,
                name: 'Stork',
                new: false,
            },
            {
                level: 24,
                name: 'Mannequin',
                new: false,
            },
            {
                level: 25,
                name: 'Soldier',
                new: false,
            },
            {
                level: 26,
                name: 'Giraffe',
                new: false,
            },
            {
                level: 27,
                name: 'Shell',
                new: false,
            },
            {
                level: 28,
                name: 'Lion',
                new: false,
            },
            {
                level: 29,
                name: 'Statue',
                new: false,
            },
            {
                level: 30,
                name: 'Bike',
                new: false,
            },
            {
                level: 31,
                name: 'Japanese',
                new: false,
            },
            {
                level: 32,
                name: 'Man',
                new: false,
            },
            {
                level: 33,
                name: 'Wave runner',
                new: false,
            },
            {
                level: 34,
                name: 'Hat',
                new: false,
            },
            {
                level: 35,
                name: 'Movie',
                new: false,
            },
            {
                level: 36,
                name: 'Excavator',
                new: false,
            },
            {
                level: 37,
                name: 'Boy',
                new: false,
            },
            {
                level: 38,
                name: 'Movie',
                new: false,
            },
            {
                level: 39,
                name: 'Game',
                new: false,
            },
            {
                level: 40,
                name: 'Rooster',
                new: false,
            },
            {
                level: 41,
                name: 'Glass',
                new: false,
            },
            {
                level: 42,
                name: 'Cow',
                new: false,
            },
            {
                level: 43,
                name: 'Champagne',
                new: false,
            },
            {
                level: 44,
                name: 'Parrot',
                new: false,
            },
            {
                level: 45,
                name: 'Ape',
                new: false,
            },
            {
                level: 46,
                name: 'Racoon',
                new: false,
            },
            {
                level: 47,
                name: 'Air-operated Hammer',
                new: false,
            },
            {
                level: 48,
                name: 'Car',
                new: false,
            },
            {
                level: 49,
                name: 'Tow truck',
                new: false,
            },
            {
                level: 50,
                name: 'Girl',
                new: false,
            },
            {
                level: 51,
                name: 'Tent',
                new: false,
            },
            {
                level: 52,
                name: 'Ship',
                new: false,
            },
            {
                level: 53,
                name: 'Knight',
                new: false,
            },
            {
                level: 54,
                name: 'Flag',
                new: false,
            },
            {
                level: 55,
                name: 'Island',
                new: false,
            },
            {
                level: 56,
                name: 'Fireplace',
                new: false,
            },
            {
                level: 57,
                name: 'Antiquity',
                new: false,
            },
            {
                level: 58,
                name: 'Grapes',
                new: false,
            },
            {
                level: 59,
                name: 'Plum',
                new: false,
            },
            {
                level: 60,
                name: 'Sailor',
                new: false,
            },
            {
                level: 61,
                name: 'Dinosaur',
                new: false,
            },
            {
                level: 62,
                name: 'Puma',
                new: false,
            },
            {
                level: 63,
                name: 'Deer',
                new: false,
            },
            {
                level: 64,
                name: 'Banana',
                new: false,
            },
            {
                level: 65,
                name: 'Watch',
                new: false,
            },
            {
                level: 66,
                name: 'Paper',
                new: false,
            },
            {
                level: 67,
                name: 'Jug',
                new: false,
            },
            {
                level: 68,
                name: 'Starfish',
                new: false,
            },
            {
                level: 69,
                name: 'Vacation',
                new: false,
            },
            {
                level: 70,
                name: 'Japanese she',
                new: false,
            },
            {
                level: 71,
                name: 'Lock',
                new: false,
            },
            {
                level: 72,
                name: 'House',
                new: false,
            },
            {
                level: 73,
                name: 'Puppy',
                new: false,
            },
            {
                level: 74,
                name: 'Builder',
                new: false,
            },
            {
                level: 75,
                name: 'Bear',
                new: false,
            },
            {
                level: 76,
                name: 'Lion',
                new: false,
            },
            {
                level: 77,
                name: 'Clock',
                new: false,
            },
            {
                level: 78,
                name: 'Knight',
                new: false,
            },
            {
                level: 79,
                name: 'Cocktail',
                new: false,
            },
            {
                level: 80,
                name: 'Cartoon character',
                new: false,
            },
            {
                level: 81,
                name: 'Car',
                new: false,
            },
            {
                level: 82,
                name: 'Mermaid',
                new: false,
            },
            {
                level: 83,
                name: 'Bunny',
                new: false,
            },
            {
                level: 84,
                name: 'Horse',
                new: false,
            },
            {
                level: 85,
                name: 'Leaf',
                new: false,
            },
            {
                level: 86,
                name: 'Acrobats',
                new: false,
            },
            {
                level: 87,
                name: 'City',
                new: false,
            },
            {
                level: 88,
                name: 'Bicyclist',
                new: false,
            },
            {
                level: 89,
                name: 'Emblem',
                new: false,
            },
            {
                level: 90,
                name: 'Girl',
                new: false,
            },
            {
                level: 91,
                name: 'Fan',
                new: false,
            },
            {
                level: 92,
                name: 'Snake',
                new: false,
            },
            {
                level: 93,
                name: 'Violin',
                new: false,
            },
            {
                level: 94,
                name: 'Viking',
                new: false,
            },
            {
                level: 95,
                name: 'Pony',
                new: false,
            },
            {
                level: 96,
                name: 'Ape',
                new: false,
            },
            {
                level: 97,
                name: 'Browser',
                new: false,
            },
            {
                level: 98,
                name: 'Volcano',
                new: false,
            },
            {
                level: 99,
                name: 'Leaves',
                new: false,
            },
            {
                level: 100,
                name: 'Ship',
                new: false,
            },
            {
                level: 101,
                name: 'Cartoon character',
                new: false,
            },
            {
                level: 102,
                name: 'Mermaid',
                new: false,
            },
            {
                level: 103,
                name: 'Man',
                new: false,
            },
            {
                level: 104,
                name: 'Flower',
                new: false,
            },
            {
                level: 105,
                name: 'Pineapple',
                new: false,
            },
            {
                level: 106,
                name: 'Boy',
                new: false,
            },
            {
                level: 107,
                name: 'Girl',
                new: false,
            },
            {
                level: 108,
                name: 'Teacher',
                new: false,
            },
            {
                level: 109,
                name: 'Bishop',
                new: false,
            },
            {
                level: 110,
                name: 'Cat',
                new: false,
            },
            {
                level: 111,
                name: 'Planet',
                new: false,
            },
            {
                level: 112,
                name: 'Cartoon character',
                new: false,
            },
            {
                level: 113,
                name: 'Pear',
                new: false,
            },
            {
                level: 114,
                name: 'Badger',
                new: false,
            },
            {
                level: 115,
                name: 'Couple',
                new: false,
            },
            {
                level: 116,
                name: 'Statue',
                new: false,
            },
            {
                level: 117,
                name: 'East',
                new: false,
            },
            {
                level: 118,
                name: 'Ducks',
                new: false,
            },
            {
                level: 119,
                name: 'Cartoon character',
                new: false,
            },
            {
                level: 120,
                name: 'Dog',
                new: false,
            },
            {
                level: 121,
                name: 'Boy',
                new: false,
            },
            {
                level: 122,
                name: 'Gardener',
                new: false,
            },
            {
                level: 123,
                name: 'Bunny',
                new: false,
            },
            {
                level: 124,
                name: 'Man',
                new: false,
            },
            {
                level: 125,
                name: 'Vegetable',
                new: false,
            },
            {
                level: 126,
                name: 'Fly',
                new: false,
            },
            {
                level: 127,
                name: 'Pilot',
                new: false,
            },
            {
                level: 128,
                name: 'Wolf',
                new: false,
            },
            {
                level: 129,
                name: 'Parrot',
                new: false,
            },
            {
                level: 130,
                name: 'Racoon',
                new: false,
            },
            {
                level: 131,
                name: 'Cat',
                new: false,
            },
            {
                level: 132,
                name: 'Reward',
                new: false,
            },
            {
                level: 133,
                name: 'Horse',
                new: false,
            },
            {
                level: 134,
                name: 'Horse',
                new: false,
            },
            {
                level: 135,
                name: 'Crime',
                new: false,
            },
            {
                level: 136,
                name: 'Boat',
                new: false,
            },
            {
                level: 137,
                name: 'Girl',
                new: false,
            },
            {
                level: 138,
                name: 'Koala',
                new: false,
            },
            {
                level: 139,
                name: 'Cartoon character',
                new: false,
            },
            {
                level: 140,
                name: 'Crab',
                new: false,
            },
            {
                level: 141,
                name: 'Prince',
                new: false,
            },
            {
                level: 142,
                name: 'Artist',
                new: false,
            },
            {
                level: 143,
                name: 'Peacock',
                new: false,
            },
            {
                level: 144,
                name: 'Rose',
                new: false,
            },
            {
                level: 145,
                name: 'Actor',
                new: false,
            },
            {
                level: 146,
                name: 'Apples',
                new: false,
            },
            {
                level: 147,
                name: 'Little girl',
                new: false,
            },
            {
                level: 148,
                name: 'Robot',
                new: false,
            },
            {
                level: 149,
                name: 'Cactus',
                new: false,
            },
        ]
    },
    {
        level: 7,
        name: 'Sensei',
        levels: [
            {
                level: 1,
                name: 'Ball',
                new: false,
            },
            {
                level: 2,
                name: 'Plasterer',
                new: false,
            },
            {
                level: 3,
                name: 'Bird',
                new: false,
            },
            {
                level: 4,
                name: 'Cat',
                new: false,
            },
            {
                level: 5,
                name: 'Fish',
                new: false,
            },
            {
                level: 6,
                name: 'Family',
                new: false,
            },
            {
                level: 7,
                name: 'Ram',
                new: false,
            },
            {
                level: 8,
                name: 'Little girl',
                new: false,
            },
            {
                level: 9,
                name: 'Birds',
                new: false,
            },
            {
                level: 10,
                name: 'Circus',
                new: false,
            },
            {
                level: 11,
                name: 'Flower',
                new: false,
            },
            {
                level: 12,
                name: 'Psychologist',
                new: false,
            },
            {
                level: 13,
                name: 'Bull',
                new: false,
            },
            {
                level: 14,
                name: 'Gymnast',
                new: false,
            },
            {
                level: 15,
                name: 'Train',
                new: false,
            },
            {
                level: 16,
                name: 'Car',
                new: false,
            },
            {
                level: 17,
                name: 'Rhino',
                new: false,
            },
            {
                level: 18,
                name: 'Cartoon character',
                new: false,
            },
            {
                level: 19,
                name: 'Mouse',
                new: false,
            },
            {
                level: 20,
                name: 'New year',
                new: false,
            },
            {
                level: 21,
                name: 'Grapes',
                new: false,
            },
            {
                level: 22,
                name: 'Crystal ball',
                new: false,
            },
            {
                level: 23,
                name: 'Famous person',
                new: false,
            },
            {
                level: 24,
                name: 'Dove',
                new: false,
            },
            {
                level: 25,
                name: 'Hippo',
                new: false,
            },
            {
                level: 26,
                name: 'Swan',
                new: false,
            },
            {
                level: 27,
                name: 'Wine',
                new: false,
            },
            {
                level: 28,
                name: 'Octopus',
                new: false,
            },
            {
                level: 29,
                name: 'Chameleon',
                new: false,
            },
            {
                level: 30,
                name: 'Sign',
                new: false,
            },
            {
                level: 31,
                name: 'Sportsman',
                new: false,
            },
            {
                level: 32,
                name: 'Aurochs',
                new: false,
            },
            {
                level: 33,
                name: 'Flower',
                new: false,
            },
            {
                level: 34,
                name: 'Candle',
                new: false,
            },
            {
                level: 35,
                name: 'Dinosaur',
                new: false,
            },
            {
                level: 36,
                name: 'Dog',
                new: false,
            },
            {
                level: 37,
                name: 'Chess knight',
                new: false,
            },
            {
                level: 38,
                name: 'Teapot',
                new: false,
            },
            {
                level: 39,
                name: 'Horse',
                new: false,
            },
            {
                level: 40,
                name: 'Bear',
                new: false,
            },
            {
                level: 41,
                name: 'Guitarist',
                new: false,
            },
            {
                level: 42,
                name: 'Viking',
                new: false,
            },
            {
                level: 43,
                name: 'Wedding',
                new: false,
            },
            {
                level: 44,
                name: 'Girl',
                new: false,
            },
            {
                level: 45,
                name: 'Mask',
                new: false,
            },
            {
                level: 46,
                name: 'Plane',
                new: false,
            },
            {
                level: 47,
                name: 'Swans',
                new: false,
            },
            {
                level: 48,
                name: 'Record player',
                new: false,
            },
            {
                level: 49,
                name: 'Tree',
                new: false,
            },
            {
                level: 50,
                name: 'Windmill',
                new: false,
            },
            {
                level: 51,
                name: 'Cartoon character',
                new: false,
            },
            {
                level: 52,
                name: 'Pig',
                new: false,
            },
            {
                level: 53,
                name: 'Goat',
                new: false,
            },
            {
                level: 54,
                name: 'Winter',
                new: false,
            },
            {
                level: 55,
                name: 'Santa Claus',
                new: false,
            },
            {
                level: 56,
                name: 'Tree',
                new: false,
            },
            {
                level: 57,
                name: 'Proposition',
                new: false,
            },
            {
                level: 58,
                name: 'Ostrich',
                new: false,
            },
            {
                level: 59,
                name: 'Balloons',
                new: false,
            },
            {
                level: 60,
                name: 'Tree',
                new: false,
            },
            {
                level: 61,
                name: 'Parrot',
                new: false,
            },
            {
                level: 62,
                name: 'Dolphin',
                new: false,
            },
            {
                level: 63,
                name: 'Author',
                new: false,
            },
            {
                level: 64,
                name: 'Turtle',
                new: false,
            },
            {
                level: 65,
                name: 'Author',
                new: false,
            },
            {
                level: 66,
                name: 'Granny',
                new: false,
            },
            {
                level: 67,
                name: 'Fairy tale character',
                new: false,
            },
            {
                level: 68,
                name: 'Acorns',
                new: false,
            },
            {
                level: 69,
                name: 'Dance',
                new: false,
            },
            {
                level: 70,
                name: 'Indian she',
                new: false,
            },
            {
                level: 71,
                name: 'Wolf',
                new: false,
            },
            {
                level: 72,
                name: 'Pig',
                new: false,
            },
            {
                level: 73,
                name: 'Flower',
                new: false,
            },
            {
                level: 74,
                name: 'Boat',
                new: false,
            },
            {
                level: 75,
                name: 'Mask',
                new: false,
            },
            {
                level: 76,
                name: 'Owl',
                new: false,
            },
            {
                level: 77,
                name: 'Bull',
                new: false,
            },
            {
                level: 78,
                name: 'Author',
                new: false,
            },
            {
                level: 79,
                name: 'Bull-calf',
                new: false,
            },
            {
                level: 80,
                name: 'Sparrow',
                new: false,
            },
            {
                level: 81,
                name: 'Snake',
                new: false,
            },
            {
                level: 82,
                name: 'Rooster',
                new: false,
            },
            {
                level: 83,
                name: 'Bunny',
                new: false,
            },
            {
                level: 84,
                name: 'Man',
                new: false,
            },
            {
                level: 85,
                name: 'Train',
                new: false,
            },
            {
                level: 86,
                name: 'Plane',
                new: false,
            },
            {
                level: 87,
                name: 'Poetry',
                new: false,
            },
            {
                level: 88,
                name: 'Farmer',
                new: false,
            },
            {
                level: 89,
                name: 'Cat',
                new: false,
            },
            {
                level: 90,
                name: 'Island',
                new: false,
            },
            {
                level: 91,
                name: 'Spaceman',
                new: false,
            },
            {
                level: 92,
                name: 'Cartoon character',
                new: false,
            },
            {
                level: 93,
                name: 'Vacation',
                new: false,
            },
            {
                level: 94,
                name: 'Igloo',
                new: false,
            },
            {
                level: 95,
                name: 'Turtle',
                new: false,
            },
            {
                level: 96,
                name: 'Witch',
                new: false,
            },
            {
                level: 97,
                name: 'Teapot',
                new: false,
            },
            {
                level: 98,
                name: 'Street cleaner',
                new: false,
            },
            {
                level: 99,
                name: 'Rooster',
                new: false,
            },
            {
                level: 100,
                name: 'Girl',
                new: false,
            },
            {
                level: 101,
                name: 'Apple',
                new: false,
            },
            {
                level: 102,
                name: 'Nestling',
                new: false,
            },
            {
                level: 103,
                name: 'Duck',
                new: false,
            },
            {
                level: 104,
                name: 'Crocodile',
                new: false,
            },
            {
                level: 105,
                name: 'Actor',
                new: false,
            },
            {
                level: 106,
                name: 'Parrot',
                new: false,
            },
            {
                level: 107,
                name: 'Ball',
                new: false,
            },
            {
                level: 108,
                name: 'Chess',
                new: false,
            },
            {
                level: 109,
                name: 'Shrimp',
                new: false,
            },
            {
                level: 110,
                name: 'Photographer',
                new: false,
            },
            {
                level: 111,
                name: 'Agent',
                new: false,
            },
            {
                level: 112,
                name: 'Tractor',
                new: false,
            },
            {
                level: 113,
                name: 'Pineapple',
                new: false,
            },
            {
                level: 114,
                name: 'Cartoon character',
                new: false,
            },
            {
                level: 115,
                name: 'Knight',
                new: false,
            },
            {
                level: 116,
                name: 'Actor',
                new: false,
            },
            {
                level: 117,
                name: 'Bird',
                new: false,
            },
            {
                level: 118,
                name: 'Doll',
                new: false,
            },
            {
                level: 119,
                name: 'Artist',
                new: false,
            },
            {
                level: 120,
                name: 'Garlic',
                new: false,
            },
            {
                level: 121,
                name: 'Artist',
                new: false,
            },
            {
                level: 122,
                name: 'Hippo',
                new: false,
            },
            {
                level: 123,
                name: 'Chair',
                new: false,
            },
            {
                level: 124,
                name: 'Actor',
                new: false,
            },
            {
                level: 125,
                name: 'Deer',
                new: false,
            },
            {
                level: 126,
                name: 'Turtle',
                new: false,
            },
            {
                level: 127,
                name: 'Chicken',
                new: false,
            },
            {
                level: 128,
                name: 'Billiards',
                new: false,
            },
            {
                level: 129,
                name: 'Snowman',
                new: false,
            },
            {
                level: 130,
                name: 'Bird',
                new: false,
            },
            {
                level: 131,
                name: 'Birds',
                new: false,
            },
            {
                level: 132,
                name: 'Rose',
                new: false,
            },
            {
                level: 133,
                name: 'Alarm clock',
                new: false,
            },
            {
                level: 134,
                name: 'Heron',
                new: false,
            },
            {
                level: 135,
                name: 'Shell',
                new: false,
            },
            {
                level: 136,
                name: 'Stump',
                new: false,
            },
            {
                level: 137,
                name: 'Apple',
                new: false,
            },
            {
                level: 138,
                name: 'Game character',
                new: false,
            },
            {
                level: 139,
                name: 'Witch',
                new: false,
            },
            {
                level: 140,
                name: 'Swan',
                new: false,
            },
        ]
    },
    {
        level: 8,
        name: 'Shogun',
        levels: [
            {
                level: 1,
                name: 'Thief',
                new: false,
            },
            {
                level: 2,
                name: 'Meal',
                new: false,
            },
            {
                level: 3,
                name: 'Carnival',
                new: false,
            },
            {
                level: 4,
                name: 'Night',
                new: false,
            },
            {
                level: 5,
                name: 'Bunnies',
                new: false,
            },
            {
                level: 6,
                name: 'City',
                new: false,
            },
            {
                level: 7,
                name: 'Rooster',
                new: false,
            },
            {
                level: 8,
                name: 'Shadow',
                new: false,
            },
            {
                level: 9,
                name: 'American Indian',
                new: false,
            },
            {
                level: 10,
                name: 'Mammoth',
                new: false,
            },
            {
                level: 11,
                name: 'Game',
                new: false,
            },
            {
                level: 12,
                name: 'Time',
                new: false,
            },
            {
                level: 13,
                name: 'Sheriff',
                new: false,
            },
            {
                level: 14,
                name: 'Rocket',
                new: false,
            },
            {
                level: 15,
                name: 'Dolphin',
                new: false,
            },
            {
                level: 16,
                name: 'Eagle',
                new: false,
            },
            {
                level: 17,
                name: 'Pegasus',
                new: false,
            },
            {
                level: 18,
                name: 'Fish',
                new: false,
            },
            {
                level: 19,
                name: 'Volunteer work',
                new: false,
            },
            {
                level: 20,
                name: 'Phone',
                new: false,
            },
            {
                level: 21,
                name: 'Mouse',
                new: false,
            },
            {
                level: 22,
                name: 'Lion',
                new: false,
            },
            {
                level: 23,
                name: 'Unicorn',
                new: false,
            },
            {
                level: 24,
                name: 'Berries',
                new: false,
            },
            {
                level: 25,
                name: 'Man',
                new: false,
            },
            {
                level: 26,
                name: 'Dolphins',
                new: false,
            },
            {
                level: 27,
                name: 'Street cleaner',
                new: false,
            },
            {
                level: 28,
                name: 'Volcano',
                new: false,
            },
            {
                level: 29,
                name: 'Ship',
                new: false,
            },
            {
                level: 30,
                name: 'Bread',
                new: false,
            },
            {
                level: 31,
                name: 'Rooster',
                new: false,
            },
            {
                level: 32,
                name: 'Cats',
                new: false,
            },
            {
                level: 33,
                name: 'Gnomes',
                new: false,
            },
            {
                level: 34,
                name: 'Ape',
                new: false,
            },
            {
                level: 35,
                name: 'Circus',
                new: false,
            },
            {
                level: 36,
                name: 'Sheep',
                new: false,
            },
            {
                level: 37,
                name: 'Dog',
                new: false,
            },
            {
                level: 38,
                name: 'Horse',
                new: false,
            },
            {
                level: 39,
                name: 'Flash Drive',
                new: false,
            },
            {
                level: 40,
                name: 'Hand of peace',
                new: false,
            },
            {
                level: 41,
                name: 'Christmas tree',
                new: false,
            },
            {
                level: 42,
                name: 'Man',
                new: false,
            },
            {
                level: 43,
                name: 'Mammoth',
                new: false,
            },
            {
                level: 44,
                name: 'Gnome',
                new: false,
            },
            {
                level: 45,
                name: 'Flower',
                new: false,
            },
            {
                level: 46,
                name: 'Ape',
                new: false,
            },
            {
                level: 47,
                name: 'Gnome',
                new: false,
            },
            {
                level: 48,
                name: 'Artist',
                new: false,
            },
            {
                level: 49,
                name: 'Duck',
                new: false,
            },
            {
                level: 50,
                name: 'Cartoon character',
                new: false,
            },
            {
                level: 51,
                name: 'Penguin',
                new: false,
            },
            {
                level: 52,
                name: 'Apple',
                new: false,
            },
            {
                level: 53,
                name: 'Deer',
                new: false,
            },
            {
                level: 54,
                name: 'Commander',
                new: false,
            },
            {
                level: 55,
                name: 'Wrestling',
                new: false,
            },
            {
                level: 56,
                name: 'Fishing',
                new: false,
            },
            {
                level: 57,
                name: 'Chair',
                new: false,
            },
            {
                level: 58,
                name: 'Cartoon character',
                new: false,
            },
            {
                level: 59,
                name: 'Geisha',
                new: false,
            },
            {
                level: 60,
                name: 'Mouse',
                new: false,
            },
            {
                level: 61,
                name: 'Elephant',
                new: false,
            },
            {
                level: 62,
                name: 'Politician',
                new: false,
            },
            {
                level: 63,
                name: 'Man',
                new: false,
            },
            {
                level: 64,
                name: 'Giraffe',
                new: false,
            },
            {
                level: 65,
                name: 'Author',
                new: false,
            },
            {
                level: 66,
                name: 'Fortuneteller',
                new: false,
            },
            {
                level: 67,
                name: 'Animals',
                new: false,
            },
            {
                level: 68,
                name: 'Earth',
                new: false,
            },
            {
                level: 69,
                name: 'Deer',
                new: false,
            },
            {
                level: 70,
                name: 'Kitten',
                new: false,
            },
            {
                level: 71,
                name: 'Bunny',
                new: false,
            },
            {
                level: 72,
                name: 'Dog',
                new: false,
            },
            {
                level: 73,
                name: 'Novel character',
                new: false,
            },
            {
                level: 74,
                name: 'Swan',
                new: false,
            },
            {
                level: 75,
                name: 'Butterfly',
                new: false,
            },
            {
                level: 76,
                name: 'Cat',
                new: false,
            },
            {
                level: 77,
                name: 'Desert',
                new: false,
            },
            {
                level: 78,
                name: 'Trollface',
                new: false,
            },
            {
                level: 79,
                name: 'Dinosaur',
                new: false,
            },
            {
                level: 80,
                name: 'Baseball',
                new: false,
            },
            {
                level: 81,
                name: 'Ship',
                new: false,
            },
            {
                level: 82,
                name: 'Starfish',
                new: false,
            },
            {
                level: 83,
                name: 'Pineapple',
                new: false,
            },
            {
                level: 84,
                name: 'Panda',
                new: false,
            },
            {
                level: 85,
                name: 'Indian',
                new: false,
            },
            {
                level: 86,
                name: 'Bird',
                new: false,
            },
            {
                level: 87,
                name: 'Rider',
                new: false,
            },
            {
                level: 88,
                name: 'Ship',
                new: false,
            },
            {
                level: 89,
                name: 'American indian',
                new: false,
            },
            {
                level: 90,
                name: 'Waves',
                new: false,
            },
            {
                level: 91,
                name: 'Frog',
                new: false,
            },
            {
                level: 92,
                name: 'Lion',
                new: false,
            },
            {
                level: 93,
                name: 'Actor',
                new: false,
            },
            {
                level: 94,
                name: 'Lizard',
                new: false,
            },
            {
                level: 95,
                name: 'Peacock',
                new: false,
            },
            {
                level: 96,
                name: 'Night',
                new: false,
            },
            {
                level: 97,
                name: 'Emperor',
                new: false,
            },
            {
                level: 98,
                name: 'Apples',
                new: false,
            },
            {
                level: 99,
                name: 'Wrestling',
                new: false,
            },
            {
                level: 100,
                name: 'Girl',
                new: false,
            },
            {
                level: 101,
                name: 'Band',
                new: false,
            },
            {
                level: 102,
                name: 'Bee',
                new: false,
            },
            {
                level: 103,
                name: 'Squirrel',
                new: false,
            },
            {
                level: 104,
                name: 'Sneakers',
                new: false,
            },
            {
                level: 105,
                name: 'Cow',
                new: false,
            },
            {
                level: 106,
                name: 'Room',
                new: false,
            },
            {
                level: 107,
                name: 'Rain',
                new: false,
            },
            {
                level: 108,
                name: 'Knight',
                new: false,
            },
            {
                level: 109,
                name: 'Horse',
                new: false,
            },
            {
                level: 110,
                name: 'Bear',
                new: false,
            },
            {
                level: 111,
                name: 'Movie character',
                new: false,
            },
            {
                level: 112,
                name: 'Witch',
                new: false,
            },
            {
                level: 113,
                name: 'Rolls',
                new: false,
            },
            {
                level: 114,
                name: 'Fox',
                new: false,
            },
            {
                level: 115,
                name: 'Palm tree',
                new: false,
            },
            {
                level: 116,
                name: 'Boat',
                new: false,
            },
            {
                level: 117,
                name: 'Turtle',
                new: false,
            },
            {
                level: 118,
                name: 'Mouse',
                new: false,
            },
            {
                level: 119,
                name: 'Eagle',
                new: false,
            },
            {
                level: 120,
                name: 'Church',
                new: false,
            },
            {
                level: 121,
                name: 'American indian',
                new: false,
            },
            {
                level: 122,
                name: 'Kid',
                new: false,
            },
            {
                level: 123,
                name: 'Snake',
                new: false,
            },
            {
                level: 124,
                name: 'Cat',
                new: false,
            },
            {
                level: 125,
                name: 'Bear',
                new: false,
            },
            {
                level: 126,
                name: 'Sailor',
                new: false,
            },
            {
                level: 127,
                name: 'Birds',
                new: false,
            },
            {
                level: 128,
                name: 'Cat',
                new: false,
            },
            {
                level: 129,
                name: 'Squirrel',
                new: false,
            },
            {
                level: 130,
                name: 'Viking',
                new: false,
            },
            {
                level: 131,
                name: 'Fruits',
                new: false,
            },
            {
                level: 132,
                name: 'Flower',
                new: false,
            },
            {
                level: 133,
                name: 'Commander',
                new: false,
            },
            {
                level: 134,
                name: 'Pirate',
                new: false,
            },
            {
                level: 135,
                name: 'Kitten',
                new: false,
            },
            {
                level: 136,
                name: 'Dog',
                new: false,
            },
            {
                level: 137,
                name: 'Cook',
                new: false,
            },
            {
                level: 138,
                name: 'Goldfish',
                new: false,
            },
            {
                level: 139,
                name: 'Dog',
                new: false,
            },
            {
                level: 140,
                name: 'Paper',
                new: false,
            },
        ]
    },
]

export default originalData;
